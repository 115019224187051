import { ICacheOptions } from "src/app/providers/data-layers/fleet-data-layer"

export interface IPaginationConcept {
  totalPages: number
  numberOfItemsPerPage: number
  totalItems: number
}

export interface IPaginationConfigure {
  limit: number
  method: | 'trip' | 'violation' | 'driver' | 'coaching'
}
export interface IPaginationConceptPage {
  [key: string]: IPaginationConcept
}
export interface IPaginationConfigPage {
  [key: string]: IPaginationConfigure
}
export interface IPaginationRequest {
  [key:string]: IPaginationConfigPage
}
export interface IPaginationResult {
  [key:string]: IPaginationConceptPage
}
export interface IPaginationOptions {
  violationFilters?: string[]
}

export const limits : IPaginationRequest = {
  home: {
    highlights: {
      limit: 4,
      method: 'violation'
    },
    topDrivers: {
      limit: 6,
      method: 'driver'
    },
  },
  drivers: {
    highlights: {
      limit: 4,
      method: 'violation'
    },
    coaching: {
      limit: 10,
      method: 'coaching'
    }
  }
}

/*

make highlights: 4, highlights: { limit: 4, method: 'eventcount' },


then we can have topDrivers: { limit: 6, method: api}

then in the code we can say if method == api, then use the name topDrivers to call the appropriate function

have the current code filter or just check method in each iteration of limits and only look upo event data if required
then also check for api, and redirect to extract data that way, warning, api is async... do async last


*/

export interface IPolyPaginationRequest {
  entity: string
  page: number
}

export interface IPolyPaginationResponse {
  entity: string
  page: number
  limit: number
  pageSize: number
  length: number
  lastPage: boolean
}

export interface IPolyPaginationDigest {
  [key:string]: IPolyPaginationResponse
}
