export const environment = {
  production: false,
  name: "staging",
  cognito: {
    auth: {
      userPoolId: 'eu-west-2_D71nos8Qj',
      userPoolWebClientId: '764dcofisl29gjkg2escdtfer',
    },
    migration: {
      userPoolId: 'eu-west-2_EoTTjfDm9',
      userPoolWebClientId: '1oppqhlsgv81gc3u9s48pkj8eg',
      apiRegisterUrl: 'https://fleet-dev.polysurance.com:6021/admin/portal/user/create'
    }
  },
  lightmetrics: {
    apiKey: 'gfTjqCSUn2atMuCaJNZYf5XJ2NQuxl2L4D5TqvRY',
    baseUrl: 'https://fleet-staging.polysurance.com:6031/',
    fleetsUrl: 'https://fleet-staging.polysurance.com:6031/fleets/',
    // eventsUrl: 'https://fleet-staging.polysurance.com:6031/event-stream/common/'
    // baseUrl: 'https://2jbc7grh50.execute-api.eu-west-2.amazonaws.com/Prod/',
    // fleetsUrl: 'https://2jbc7grh50.execute-api.eu-west-2.amazonaws.com/Prod/fleets/',
    eventsUrl: 'https://2jbc7grh50.execute-api.eu-west-2.amazonaws.com/Prod/event-stream/common/',
    liveStreamUrl: 'https://2jbc7grh50.execute-api.eu-west-2.amazonaws.com/Prod/live/'
  },
  viking: {
    apiKey: 'implement-this',
    baseUrl: 'https://fleet-staging.polysurance.com:6021/portal/v1/',
    adminUrl: 'https://fleet-staging.polysurance.com:6021/admin/v1/',
    fleetsUrl: 'https://fleet-staging.polysurance.com:6021/portal/v1/fleets/',
    adminFleetsUrl: 'https://fleet-staging.polysurance.com:6021/admin/v1/fleets/',
    adminUserPortalUrl: 'https://portal.polysurance.com:6021/admin/portal/',
    adminGeofenceUrl: 'https://fleet-staging.polysurance.com:6021/viking/v1/geofence/',
    geoFenceEventsUrl: 'https://fleet-staging.polysurance.com:6021/viking/v1/geofenceEvent',
    eventsUrl: 'https://portal.polysurance.com:6031/event-stream/common/',
    vikingUrl: 'https://fleet-staging.polysurance.com:6021/viking/v1/',
    dashcamUrl: 'https://fleet-staging.polysurance.com:6021/dashcam/v1/'
  },
  google: {
    maps: {
      apiKey: 'AIzaSyBZgGi1Mo0rrXmzQRmAQZgrdjqO54sWxUw'
    }
  },
  nominatim: {
    url: 'nominatim.openstreetmap.org',
    defaultViewBox: 'viewbox=-25.0000%2C70.0000%2C50.0000%2C40.0000',
  },
  liveView: {
    updateFrequency: 60 * 1000,
    map: {
      defaultZoom: 8,
      maxZoom: 20,
      center: {
        lat: 25.097377234095134,
        lng: 55.16915959903615
      }
    }
  }

};
