import moment from "moment"
import { getParams } from "../generic/utils"

export interface IReqFleetCoaching {
  after: string
  before: string
  sort: 'asc' | 'desc'
  limit: number
  skip: number // use this for pagination
  coachingCompleted: boolean
  driverId: string
  eventType: string
}

export const getParamsFleetCoaching = (options: IReqFleetCoaching) : string => {
  return getParams(options)
}

export const generateFleetCoachingOptions = (overrides?: IReqFleetCoaching) => {
  return {...defaultFleetCoachingOpts, ...overrides}
}

export const defaultFleetCoachingOpts: IReqFleetCoaching = {
  after: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  before: moment().format('YYYY-MM-DD'),
  limit: 10,
  skip: 0,
  sort: 'asc',
  coachingCompleted: false,
  driverId: null,
  eventType: null
}

// Generated by https://quicktype.io

export interface IResFleetCoaching {
  limit: number;
  skip:  number;
  rows:  IResFleetCoachingRow[];
}

export interface IResFleetCoachingRow {
  driverId:                       string;
  driverName:                     string;
  tripId:                         string;
  eventType:                      string;
  timestamp:                      string;
  timestampUTC:                   string;
  longitude:                      number;
  latitude:                       number;
  speed:                          number;
  bearing:                        number;
  eventIndex:                     number;
  timeZoneId:                     string;
  mediaFiles:                     MediaFile[];
  bookmark:                       boolean;
  bookmarkedTimestamp:            string;
  coachingCompleted:              boolean;
  coachingInitiatedMetadata:      CoachingInitiatedMetadata;
  severity:                       number;
  device:                         Device;
  asset:                          Asset;
  eventVideoFile:                 string;
  tags:                           any[];
  videoDetails:                   VideoDetails;
  eventVideoFilenameUploadStatus: EventVideoFilenameUploadStatus;
}

export interface Asset {
  assetId: string;
}

export interface CoachingInitiatedMetadata {
  userType: string;
  email:    string;
  name:     string;
}

export interface Device {
  deviceId: string;
}

export interface EventVideoFilenameUploadStatus {
  status:          string;
  uploadTimestamp: string;
}

export interface MediaFile {
  mediaFileIndex: number;
  mediaFile:      string;
  source:         string;
  videoDetails:   VideoDetails;
  uploadStats:    EventVideoFilenameUploadStatus;
}

export interface VideoDetails {
  videoWidth:     number;
  videoHeight:    number;
  videoBitrate:   number;
  includesPII:    boolean;
  videoQuality:   number;
  videoType:      string;
  videoPiPType:   string;
  codec_type:     string;
  videoFrameRate: number;
  piiSubframe:    PiiSubframe;
}

export interface PiiSubframe {
  topLeftX: number;
  topLeftY: number;
  width:    number;
  height:   number;
}
