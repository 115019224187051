import { getParams } from "../generic/utils"

export interface IReqAggregateDriverTrips {
  dutyType: 'light' | 'medium' | 'heavy' | null
  groupBy: 'day' | 'month' | 'year'
  after: string | null
  before: string | null
  division: string | null
  limit: number | null
  skip: number | null // use this for pagination
  driverId?: string
}

export interface IReqAggregateAssetTrips {
  dutyType: 'light' | 'medium' | 'heavy' | null
  groupBy: 'day' | 'month' | 'year'
  after: string | null
  before: string | null
  division: string | null
  limit: number | null
  skip: number | null // use this for pagination
  assetId?: string
}

export const defaultAggregateDriverTripOpts: IReqAggregateDriverTrips = {
  groupBy: null,
  after: null,
  before: null,
  division: null,
  limit: null,
  dutyType: null,
  skip: null,
  driverId: null
}

export const defaultAggregateAssetTripOpts: IReqAggregateAssetTrips = {
  groupBy: null,
  after: null,
  before: null,
  division: null,
  limit: null,
  dutyType: null,
  skip: null,
  assetId: null
}

export const getParamsAggregateDriverTrips = (options: IReqAggregateDriverTrips) : string => {
  return getParams(options)
}

export const generateAggregateDriverTripsOptions = (options?: IReqAggregateDriverTrips) => {
  return {...defaultAggregateDriverTripOpts, ...options}
}

// Generated by https://quicktype.io

export interface IResAggregateDriverTrips {
  limit:      number;
  skip:       number;
  driverName: string;
  rows:       IResAggregateDriverTripsRow[];
}

export interface IResAggregateDriverTripsRow {
  key:   string;
  value: IResAggregateDriverTripsRowValue;
}

export interface IResAggregateDriverTripsRowValue {
  tripDistance: number;
  eventCount:   { [key: string]: number };
  tripCount:    number;
  tripDuration: number;
  score:        number;
}

export type IResAggregateAssetTrips = IResAggregateDriverTrips
export type IResAggregateAssetTripsRow = IResAggregateDriverTripsRow

