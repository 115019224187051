<div fxLayout="column">
  <h1 mat-dialog-title>Add Driver</h1>
  <div mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="end start">
      <mat-form-field [style.width]="'100%'" appearance="outline">
        <input matInput placeholder="Email Address" [(ngModel)]="saved.email">
      </mat-form-field>
      <mat-form-field [style.width]="'100%'" appearance="outline">
        <input matInput placeholder="Telephone" [(ngModel)]="saved.telephone">
      </mat-form-field>
      <mat-form-field [style.width]="'100%'" appearance="outline">
        <input matInput placeholder="Driver Name" [(ngModel)]="saved.driverName">
      </mat-form-field>
      <mat-form-field [style.width]="'100%'" appearance="outline">
        <input matInput placeholder="Driver ID" [(ngModel)]="saved.driverId">
      </mat-form-field>
      <!-- <vex-user-type-selector [style.width]="'100%'"></vex-user-type-selector> -->
      <vex-asset-id-selector [style.width]="'100%'" [fleetType]="data.fleetType" [selectedAssetId]="saved.selectedAssetId" (onAssetChange)="onAssetChange($event)"></vex-asset-id-selector>
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end">
    <div [mat-dialog-close]="data" cdkFocusInitial mat-flat-button=""class="mat-focus-indicator mat-flat-button mat-button-base">
      <span class="mat-button-wrapper"> CANCEL </span>
      <span matripple="" class="mat-ripple mat-button-ripple"></span>
      <span class="mat-button-focus-overlay"></span>
    </div>
    <div [mat-dialog-close]="saved" cdkFocusInitial mat-flat-button="" color="accent" class="mat-focus-indicator mat-flat-button mat-button-base mat-accent">
      <span class="mat-button-wrapper"> OK </span>
      <span matripple="" class="mat-ripple mat-button-ripple"></span>
      <span class="mat-button-focus-overlay"></span>
    </div>
  </div>
</div>
