import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { QuadStatsComponent } from './quad-stats.component';
import { ContentLoaderModule } from '@ngneat/content-loader';

@NgModule({
  declarations: [QuadStatsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    ContentLoaderModule
  ],
  providers: [
  ],
  exports: [QuadStatsComponent]
})
export class QuadStatsModule {
}
