import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { EDuration, IDateRange, SECONDS_TO_HOURS } from 'src/app/concepts/utils/chrono-utils';
import { FleetDataLayer, ICacheOptions } from 'src/app/providers/data-layers/fleet-data-layer';
import { PaginationService } from 'src/app/providers/services/pagination-service';
import { BehaviorSubject, Observable, of, scan, Subscription, tap, take, from } from 'rxjs';
import { IPaginationResult } from 'src/app/concepts/pagination/pagination';
import { IReqAggregateFleetTrips, IResAggregateFleetTripsRow, generateAggregateTripsOptions, FleetTripsAggregateValue } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-aggregate-fleet-trips';
import { DateTime } from 'luxon';
import { IPortalUser, UserService } from 'src/app/providers/services/user-service';
import { VikingAPIService } from 'src/app/providers/services/viking-api-service';
import { TranslateService } from '@ngx-translate/core';
import { FleetService } from 'src/app/providers/services/fleet-service';



@Component({
  selector: 'vex-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public quadPanelLabels: string[] = [ this.translate.instant('home.quadTrips'), this.translate.instant('home.quadDistance'), this.translate.instant('home.quadIncidents'), this.translate.instant('home.quadDuration')]
  public quadStatsValues$: Observable<string[]>
  public incidentSummaryData$: Observable<FleetTripsAggregateValue>
  public numResourcesToLoad: number = 1
  public aggregateData: FleetTripsAggregateValue
  private _subs: Subscription[] = []
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  //public fleetLabels: string[] = ['Unigaz', 'Sigma', 'Whippet', 'Noria-KSA'];
  language: string = this.translate.currentLang;
  private _fleetType: boolean;
  private user: string
  public fleetType: boolean;



  @Input() reload: boolean;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reload && changes.reload.currentValue === true) {
      this._runQuery();
    }
  }

  public dateRange: IDateRange = {
    from: moment().subtract(1, 'month').format("YYYY-MM-DD"),
    to: moment().add(1,'day').format("YYYY-MM-DD")
  }

  public paginatorData$: Observable<IPaginationResult>

  constructor(
    public fdl: FleetDataLayer,
    public vapi: VikingAPIService,
    private translate: TranslateService,
    private userService: UserService,
    private fleetService: FleetService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }
  
   async ngOnInit(): Promise<void>{
    await this.fleetService.setFleet();
    this.fleetService.fleetType$.subscribe(fleetType => {
      this._fleetType = fleetType;
      this._runQuery();
      console.log('fleet type2', this._fleetType);
    });
    this.setDateRange(EDuration.month)
    this._runQuery()
  }


  private aggregateTripsDatasource = ((options: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> => {
    return this.fdl.getAggregateTrips(generateAggregateTripsOptions(options))
  });

  private aggregateTripsDatasourceV2 = ((options: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> => {
    return this.fdl.getAggregateTripsV2(generateAggregateTripsOptions(options))
  });

  private  _runQuery(){

    this.numResourcesToLoad = 1

    if(this._subs?.length > 0) {
      this._subs.forEach(sub => sub.unsubscribe())
      this._subs = []
    }

    this._increment = new BehaviorSubject<number>(0);
    this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    this._subs.push(this.loaded.subscribe(loadedCounter => {
      if(loadedCounter === this.numResourcesToLoad) {
        if(this._fleetType){
          this._extractAggregateDataV2()
        }else{
          this._extractAggregateData()
        }
      }
    }))

    const before = DateTime.fromFormat(this.dateRange.to, 'yyyy-LL-dd').plus({'days':1}).toFormat('yyyy-LL-dd')


    if(this._fleetType){
      this.aggregateTripsDatasourceV2({
        after: this.dateRange.from, before: before, limit: 123, skip: null, groupBy: null,
        division: '',
        dutyType: 'light',
        sort: 'asc'
      }).subscribe(res => {
        this.aggregateData = res?.reduce((acc: any, row) => {
          Object.keys(acc).length === 0
            ? acc = row
            : acc = {
              ...acc,
              tripDistance: acc.tripDistanceInMeters,
              tripDuration: acc.durationInSeconds,
              tripCount: acc.totalTrips,
              eventCount: acc.ViolationsCount
            }
          return acc
        }, {})
        this._increment.next(1)
      })
    }else{
      this._getTripsAggregate({after: this.dateRange.from, before: before, limit: 123, skip: null, groupBy: null}).pipe(take(1)).subscribe(res => {
        this.aggregateData = res?.reduce((acc: any, row) => {
          Object.keys(acc).length === 0
            ? acc = row.value
            : acc = {
              ...acc,
              tripDistance: acc.tripDistance + row.value.tripDistance,
              tripDuration: acc.tripDuration + row.value.tripDuration,
              tripCount: acc.tripCount + row.value.tripCount,
              eventCount: Object.keys(row.value.eventCount).reduce((eventObj, key) => {
                return { ...eventObj, [key]: acc.eventCount[key] + row.value.eventCount[key] }
              }, {})
            }
          return acc
        }, {})
        this._increment.next(1)
      })
    }
  }

  private _getTripsAggregate(options: ICacheOptions): Observable<IResAggregateFleetTripsRow[]> {
    const cacheName = `pagination-${new Date().getTime()}`
    this.fdl.addCache<IResAggregateFleetTripsRow>(cacheName, this.aggregateTripsDatasource, {
      ...options,
      limit: null,
      skip: null,
      groupBy: null,
    }, true)
    const cache = this.fdl.getCacheType<IResAggregateFleetTripsRow>(cacheName)
    return cache
      .getPage(0).pipe(tap(() => this.fdl.removeCache(cacheName)))
  }

  private _extractAggregateData() {
    this.quadStatsValues$ = of([
      this.aggregateData?.tripCount?.toString() || "0",
      Math.round(this.aggregateData?.tripDistance || 0).toLocaleString('en-US'),
      Math.round(((this.aggregateData?.eventCount?.total || 0) / (this.aggregateData?.tripDistance || 1) * 100.0)).toString(),
      Math.round(((this.aggregateData?.tripDuration || 0) / SECONDS_TO_HOURS)).toLocaleString('en-US')
    ])
    this.incidentSummaryData$ = of(this.aggregateData)
  }

 private _extractAggregateDataV2() {
    this.quadStatsValues$ = of([
      this.aggregateData?.totalTrips?.toString() || "0",
      Math.round((this.aggregateData?.tripDistanceInkm || 0)).toLocaleString('en-US'),
      Math.round((this.aggregateData?.totalViolations || 0) / (Math.round((this.aggregateData?.tripDistanceInkm)) || 1)).toString(),
      Math.round(((this.aggregateData?.durationInms || 0) / 3600000)).toLocaleString('en-US')
    ])
    console.log('this.aggregateDataaa', this.aggregateData)
    this.incidentSummaryData$ = of(this.aggregateData)
  }

  public onDurationSelected(duration: string) {
    console.log( 'duration' ,EDuration[this.translate.instant(duration)])
    this.setDateRange(EDuration[duration])
    this._runQuery()
  }

  public setDateRange(duration: EDuration): void {
    const range : IDateRange = {
      from: "",
      to: moment().add({'days':1}).format("YYYY-MM-DD")
    }
    switch(duration) {
      case EDuration.week: {
        range.from = moment().subtract(1, 'week').format("YYYY-MM-DD")
        break
      }
      case EDuration.month: {

        range.from = moment().subtract(1, 'month').format("YYYY-MM-DD")
        break
      }
      case EDuration.quarter: {
        range.from = moment().subtract(4, 'month').format("YYYY-MM-DD")
        break
      }
      case EDuration.year: {
        range.from = moment().subtract(1, 'year').format("YYYY-MM-DD")
        break
      }
    }
    this.dateRange = range
  }

  

  
}
