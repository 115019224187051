import { IIncidentTableResult } from "../concepts/incidents/incident-table";

export const mockData: IIncidentTableResult[] = [
  {
    incident: "Harsh Cornering - 17:29",
    action: ["trip.details.play_video"],
    type: 'low'
  },
  {
    incident: "Harsh Cornering - 17:58",
    action: ["trip.details.play_video"],
    type: 'low'
  },
  {
    incident: "Harsh Cornering - 18:21",
    action: ["trip.details.play_video"],
    type: 'medium'
  }
];
