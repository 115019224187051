  <div fxFlexFill fxLayout="column" class="container live-view">
    <div fxFlex fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.lt-md="column" class="content">
      <div fxFlex class="map">
        <google-map #map width="100%" height="100%" [options]="mapOptions">
          <map-marker #marker="mapMarker" *ngFor="let trip of mapData$ | async" (mapClick)="markerClicked(trip, marker)" [position]="trip.position" [options]="trip.options"></map-marker>
          <map-info-window [style.color]="'black'" >
            <div [innerHTML]="infoContent"></div>
          </map-info-window>
        </google-map>
        <div *ngIf="markersLoaded$ | async" class="spinner-overlay" fxLayout="row" fxLayoutAlign="center center">
          <div class="spinner">
            <mat-progress-spinner diameter="200" role="progressbar" tabindex="-1" class="spinner mat-progress-spinner mat-progress-spinner-indeterminate-animation" mode="indeterminate" >
              <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 12 12" >
                <circle cx="50%" cy="50%" r="5" style="animation-name: mat-progress-spinner-stroke-rotate-20; stroke-dasharray: 31.4159px; stroke-width: 10%;">
                </circle>
              </svg>
            </mat-progress-spinner>        
          </div>
        </div>
      </div>
      <mat-card class="details">
        <form [formGroup]="filterFormGroup" class="filter" fxFlexLayout="row" fxLayoutGap="20px" fxFlexFullWidth fxLayoutAlign="start center" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
          <mat-form-field fxFlex="1 0 85%" class="field" appearance="outline" >
            <input formControlName="filter" matInput (input)="onFilterUpdate($event)" placeholder="{{ 'liveView.assetIdSearch'  | translate }}" [value]="filterAssetId">
          </mat-form-field>
        </form>
        <div class="table">
          <vex-live-view-assets-table [tableData]="tableData$ | async" [loaded$]="tableDataLoaded$" (tabChange)="onTabChange($event)"></vex-live-view-assets-table>
        </div>
      </mat-card>
    </div>
  </div>


  <!-- <div class="filter" fxFlexLayout="row" fxLayoutGap="20px">
    <mat-form-field class="field" appearance="outline">
      <input matInput placeholder="Enter Asset ID" [value]="filterAssetId">
    </mat-form-field>
  </div>
  <vex-widget-table [columns]="tableColumns"
    [data]="tableData"
    class="table">
  </vex-widget-table> -->
