
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { IncidentService } from 'src/app/providers/services/incident-service';
import { UserTypeSelectorComponent } from './user-type-selector.component';

@NgModule({
  declarations: [UserTypeSelectorComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FlexLayoutModule,
    NgxTippyModule,
    WidgetTableModule,
  ],
  providers: [
    IncidentService,
  ],
  exports: [UserTypeSelectorComponent]
})
export class UserTypeSelectorModule {
}
