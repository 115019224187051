<div>
  <!-- <div class="border-b px-6 flex items-right">
    <button mat-icon-button type="button">
      <mat-icon class="text-secondary" svgIcon="mat:cloud_download"></mat-icon>
    </button>

    <button mat-icon-button type="button">
      <mat-icon class="text-secondary" svgIcon="mat:more_horiz"></mat-icon>
    </button>
  </div> -->

  <div class="pt-3 pb-1 pr-6" >
    <div [style]="{'position': 'relative'}">
      <div [style]="{'width': '100%', 'padding-bottom': '50%', 'visibility': 'hidden'}"></div>
      <vex-chart [options]="options" [series]="series"
        [style]="{'width': '100%', 'margin-top': '-50%', 'height': '100%', 'position': 'absolute'}">
      </vex-chart>
    </div>
  </div>
</div>
