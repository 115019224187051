import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { Observable, tap, scan, of, Subscription, BehaviorSubject, from } from 'rxjs';
import { generateViolationOptions, IResFleetViolationsRow } from 'src/app/concepts/api/lightmetrics/fleets/incidents/get-fleet-violations';
import { generateTripsOptions, ITrip } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-fleet-trips';
import { IDateRange, SECONDS_TO_HOURS } from 'src/app/concepts/utils/chrono-utils';
import { FleetDataLayer, ICacheOptions } from 'src/app/providers/data-layers/fleet-data-layer';

@Component({
  selector: 'vex-quad-stats',
  templateUrl: './quad-stats.component.html',
  styleUrls: ['./quad-stats.component.scss']
})
export class QuadStatsComponent implements OnInit, OnDestroy {

  @Input() labels: string[]
  @Input() values$: Observable<string[]>
  // private _dateRange: IDateRange
  // @Input() set dateRange(value: IDateRange) {
  //   this._dateRange = value
  //   this._runQuery()
  // }
  // public tripData: ITrip[]
  // public violationsData: IResFleetViolationsRow[]
  // public quadPanelValues$: Observable<string[]>
  // private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  // public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  // private _numResourcesToLoad: number = 2

  // private _subs: Subscription[] = []

  constructor(
    private fdl: FleetDataLayer
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    // this._subs.forEach(sub => sub.unsubscribe())
  }

  private _runQuery(): void {
    // this._numResourcesToLoad = 2

    // if(this._subs?.length > 0) {
    //   this._subs.forEach(sub => sub.unsubscribe())
    //   this._subs = []
    // }

    // this._increment = new BehaviorSubject<number>(0);
    // this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    // this._subs.push(this.loaded.subscribe(loadedCounter => {
    //   if(loadedCounter === this._numResourcesToLoad) {
    //     this._extractQuadPanelData()
    //   }
    // }))

    // const tripsDatasource = ((options: ICacheOptions) : Observable<ITrip[]> => {
    //   return this.fdl.getTrips(generateTripsOptions(options))
    // });

    // const violationsDatasource = ((options: ICacheOptions) : Observable<IResFleetViolationsRow[]> => {
    //   return this.fdl.getViolations(generateViolationOptions(options))
    // });

    // this.fdl.addCache<ITrip>('trips', tripsDatasource, {
    //   after: this._dateRange.from,
    //   before: this._dateRange.to,
    //   limit: 1000000,
    //   skip: 0
    // })
    // this.fdl.addCache<IResFleetViolationsRow>('violations', violationsDatasource, {
    //   after: this._dateRange.from,
    //   before: this._dateRange.to,
    //   limit: 1000000,
    //   skip: 0
    // })

    // this.fdl.getCacheType<ITrip>('trips')
    //   .getPage(0)
    //   .subscribe(res => {
    //     this.tripData = res?.filter(row => moment(row.startTime).isBetween(moment(this._dateRange.from), moment(this._dateRange.to))) || []
    //     this._increment.next(1)
    //   })

    // this.fdl.getCacheType<IResFleetViolationsRow>('violations')
    //   .getPage(0)
    //   .subscribe(res => {
    //     this.violationsData = res?.filter(row => moment(row.timestamp).isBetween(moment(this._dateRange.from), moment(this._dateRange.to))) || []
    //     this._increment.next(1)
    //   })
  }


  public counter(i: number) : number[] {
    return new Array(i);
  }

  // private _extractQuadPanelData() {
  //   const totalDistance = this.tripData?.reduce((acc: number, trip: ITrip) => acc + trip.tripDistance, 0.0)
  //   const numViolations = this.violationsData?.length || 0
  //   const totalDurationInHours = (this.tripData?.reduce((acc: number, trip: ITrip) => acc + trip.duration, 0.0) || 0) / SECONDS_TO_HOURS
  //   this.quadPanelValues$ = of([
  //     this.tripData?.length.toString(),
  //     totalDistance?.toFixed(2),
  //     Math.round((numViolations / (totalDistance || 1) * 100.0)).toString(),
  //     totalDurationInHours.toFixed(2)
  //   ])
  //   this._increment.next(1)
  // }


}
