import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { IncidentService } from 'src/app/providers/services/incident-service';
import { IncidentSelectorModule } from '../../incident-selector/incident-selector.module';
import { RequireCoachingComponent } from './require-coaching.component';

@NgModule({
  declarations: [RequireCoachingComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    IncidentSelectorModule,
    NgxTippyModule,
    WidgetTableModule,
  ],
  providers: [
    IncidentService,
  ],
  exports: [RequireCoachingComponent]
})
export class RequireCoachingModule {
}
