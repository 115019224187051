<div fxFullWidth [style.height]="'100%'">
  <div fxLayout="column" fxLayoutAlign="center" class="section-card" [style.height]="'100%'">
    <div class="section-container" fxFlex fxLayout="column" fxFlexLayout="space-between" [style.height]="'100%'">
      <!-- <div class="section-header" fxLayout="row" fxLayoutAlign="space-between center" fxFullWidth> -->
      <!-- </div> -->
      <!-- <mat-card fxFlex [style.height]="'100%'" class="section-content" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="0px" fxFullWidth> -->
        <!-- <ng-container *ngIf="pagination; else loadingPagination"> -->
          <!-- <vex-widget-table [lastPage]="false" (page)="onPageEvent($event)" [columns]="tableColumns" -->
          <div *ngIf="tableDataLoaded$ | async"  >
            <vex-widget-table [loaded]="tableDataLoaded$ | async" [paginationResponse]="paginationDigest.topDrivers" (paginationRequest)="onTopDriversPaginationRequest('topDrivers', $event)" [columns]="tableColumns"
            [data]="driversTableData$ | async"
            class="sm:col-span-2" >
          </vex-widget-table>
          </div>
            
        <!-- </ng-container>
        <ng-template #loadingPagination>
        </ng-template> -->
      <!-- </mat-card> -->
    </div>
  </div>
</div>
