
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import L, { Map, Control, DomUtil, ZoomAnimEvent , Layer, MapOptions, tileLayer, latLng, marker, LatLngLiteral, Marker } from 'leaflet';

@Component({
  selector: 'app-osm-map',
  template: `<div class="map-container" leaflet
     [leafletOptions]="options"
     (leafletMapReady)="onMapReady($any($event))"
     (leafletMapZoomEnd)="onMapZoomEnd($any($event))"
    ></div>`,
  styles: [`.map-container{
    width:100%;
    height:100%;
    position: inherit;
    }`]
})
export class OsmMapComponent implements OnInit, OnDestroy {
  @Output() map$: EventEmitter<Map> = new EventEmitter;
  @Output() zoom$: EventEmitter<number> = new EventEmitter;
  @Input() options: MapOptions
  public map: Map;
  public zoom: number;
  public marker: Marker

  constructor() {
  }

  ngOnInit() {
    const iconRetinaUrl = 'assets/marker-icon-2x.png';
    const iconUrl = 'assets/marker-icon.png';
    const shadowUrl = 'assets/marker-shadow.png';
    const iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    L.Marker.prototype.options.icon = iconDefault;
  }

  ngOnDestroy() {
    this.marker.removeFrom(this.map)
    this.map.clearAllEventListeners();
    // this.map.remove();
  };

  onMapReady(map: Map) {
    this.map = map;
    this.map$.emit(map);
    this.zoom = map.getZoom();
    this.zoom$.emit(this.zoom);
    this.marker = marker([(this.options.center as LatLngLiteral).lat, (this.options.center as LatLngLiteral).lng]).addTo(this.map);
  }

  onMapZoomEnd(e: ZoomAnimEvent) {
    this.zoom = e.target.getZoom();
    this.zoom$.emit(this.zoom);
  }
}
