import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { WidgetTableModule } from "src/@vex/components/widgets/widget-table/widget-table.module";
import { LiveViewAssetsTableModule } from "src/app/components/live-view/trip-details-incidents-table/live-view-assets-table.module";
import { LiveViewComponent } from "./live-view.component";
import { LiveViewRoutingModule } from './live-view.routing.module';
import { TranslateModule } from "@ngx-translate/core";
import { StreamingDialogModule } from "./dialogs/streaming/streaming-dialog.module";
import { AlertDialogModule } from "src/@vex/components/dialogs/alert/alert-dialog.module";

@NgModule({
  declarations: [LiveViewComponent],
  imports: [
    CommonModule,
    LiveViewRoutingModule,
    MatCardModule,
    FlexLayoutModule,
    GoogleMapsModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    WidgetTableModule,
    LiveViewAssetsTableModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    StreamingDialogModule,
    AlertDialogModule,
    TranslateModule,
  ],
  exports:[
    LiveViewComponent,
    TranslateModule
  ],
})
export class LiveViewModule {
}
