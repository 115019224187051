import { CoachingRequiredResult } from "../concepts/coaching/coaching";
import { TopDriverResult } from "../concepts/user/driver/driver";

export const coachingRequiredData: CoachingRequiredResult[] = [
  {
    name: 'Jason Reeve',
    incidents: '3',
    distance: '123',
    risk: 'Harsh Breaking'
  },
  {
    name: 'Cameron Tope',
    incidents: '6',
    distance: '432',
    risk: 'Harsh Cornering'
  },
  {
    name: 'Anwar Ahmed',
    incidents: '9',
    distance: '342',
    risk: 'Harsh Acceleration'
  }
];
