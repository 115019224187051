import moment from "moment"
import { ICacheOptions } from "src/app/providers/data-layers/fleet-data-layer"
import { getParams } from "../generic/utils"

export interface IReqFleetTrips {
  after: string
  before: string
  division: string | null
  limit: number
  dutyType: 'light' | 'medium' | 'heavy' | null
  skip: number // use this for pagination
  minEvents: number | null, // can't see us using event limits
  maxEvents: number | null,
  driverIds: string | null, // postman docs show 'cil' whatever that means and it is vague
  sort: 'asc' | 'desc'
  includeCustomTripKeys: boolean // no docs!
}

export interface IReqFleetTripsV2 {
  after: string
  before: string
  division: string | null
  limit: number
  dutyType: 'light' | 'medium' | 'heavy' | null
  skip: number // use this for pagination
  minEvents: number | null, // can't see us using event limits
  maxEvents: number | null,
  driverIds: string | null, // postman docs show 'cil' whatever that means and it is vague
  assetId: string | null
  sort: 'asc' | 'desc'
}

export const getParamsFleetTrips = (options: IReqFleetTrips) : string => {
  return getParams(options)
}

export const getParamsFleetTripsV2 = (options: IReqFleetTripsV2) : string => {
  return getParams(options)
}

export const generateTripsOptions = (options: ICacheOptions) => {
  return {...defaultTripOpts, ...options}
}

// Generated by https://quicktype.io

export interface IResFleetTrips {
  limit: number;
  skip:  number;
  rows:  ITrip[];
}

export interface ITrip {
  fleetId:                           string;
  tripId:                            string;
  tripDistance:                      number;
  startTime:                         string;
  startTimeUTC:                      string;
  endTime:                           string;
  endTimeUTC:                        string;
  driverId:                          string;
  driverName?:                       string;
  eventCount:                        { [key: string]: number };
  customEventCount:                  CustomEventCount;
  asset:                             Asset;
  dvrEnabled:                        boolean;
  cameraSerialId:                    string;
  timezoneOffset:                    number;
  ongoing:                           boolean;
  timeZoneId:                        string;
  device:                            Device;
  cameraMountingStatusFiles?:        CameraMountingStatusFile[];
  ignitionId:                        string;
  frPersonsList?:                    any[];
  sampleDriverImage?:                string;
  sampleDriverImageFaceCoordinates?: SampleDriverImageFaceCoordinates;
  statusUpdateTimestamp:             string;
  firstLocation:                     Location;
  lastLocation:                      Location;
  lastKnownLocation:                 Location;
  videoCaptureStartTimeUTC:          string;
  videoCaptureStartTime:             string;
  cameraConnected:                   boolean;
  duration:                          number;
  edvrEnabled:                       boolean;
  tripDataUploaded:                  boolean;
  cameraMountingStatus?:             CameraMountingStatus;
}

export interface IVehicleIdleTime {
  totalIdleDurationMilliseconds: number;
  device: vehicleIdleTimeDevice
  day: string;
  assetId: string;
}

export interface vehicleIdleTimeDevice{
    model: string;
    deviceId: string;
}

export interface ITripV2 {
  cameraId: any
  assetId: any
  distance: any
  cameraName: any
  violations: any
  fleetId:                           string;
  tripId:                            string;
  tripDistance:                      number;
  startTime:                         string;
  startTimeUTC:                      string;
  endTime:                           string;
  endTimeUTC:                        string;
  driverId:                          string;
  driverName?:                       string;
  eventCount:                        { [key: string]: number };
  customEventCount:                  CustomEventCount;
  asset:                             Asset;
  cameraSerialId:                    string;
  timezoneOffset:                    number;
  ongoing:                           boolean;
  timeZoneId:                        string;
  device:                            Device;
  cameraMountingStatusFiles?:        CameraMountingStatusFile[];
  ignitionId:                        string;
  frPersonsList?:                    any[];
  sampleDriverImage?:                string;
  sampleDriverImageFaceCoordinates?: SampleDriverImageFaceCoordinates;
  statusUpdateTimestamp:             string;
  firstLocation:                     Location;
  lastLocation:                      Location;
  lastKnownLocation:                 Location;
  videoCaptureStartTimeUTC:          string;
  videoCaptureStartTime:             string;
  cameraConnected:                   boolean;
  duration:                          number;
  edvrEnabled:                       boolean;
  tripDataUploaded:                  boolean;
}

export interface Asset {
  assetId:         string;
  dutyType:        DutyType;
  cameraType:      CameraType;
  ridecamPlusPlan: RidecamPlusPlan;
  plusPackages:    Package[];
  packages:        Package[];
  metadata:        Metadata;
}

export enum CameraType {
  RidecamPlus = "RIDECAM_PLUS",
}

export enum DutyType {
  Heavy = "heavy",
  Medium = "medium",
  Light = "light",
}

export interface Metadata {
}

export enum Package {
  AdasTg = "ADAS_TG",
  DVR = "DVR",
  DmsDistraction = "DMS_DISTRACTION",
  DmsFaceid = "DMS_FACEID",
  Edvr = "EDVR",
  GSensor = "G-sensor",
}

export enum RidecamPlusPlan {
  Pro = "PRO+",
  Standard = "STANDARD",
}

export interface CameraMountingStatus {
  eventVideoFile: string;
  isBadMounting:  boolean;
}

export interface CameraMountingStatusFile {
  isBadMounting:  boolean;
  source:         Source;
  mediaFileIndex: number;
  mediaFile:      string;
}

export enum Source {
  SideBySide = "SIDE_BY_SIDE",
}

export interface CustomEventCount {
  total: number;
}

export interface Device {
  model:    Model;
  deviceId: string;
}

export enum Model {
  SprdKMC28 = "sprd KMC28",
}

export interface Location {
  latitude:  number;
  longitude: number;
  bearing:   number;
  speed:     number;
  accuracy:  number;
}

export interface SampleDriverImageFaceCoordinates {
  topLeftX:     number;
  topLeftY:     number;
  bottomRightX: number;
  bottomRightY: number;
}

export const defaultTripOpts: IReqFleetTrips = {
  after: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  before: moment().format('YYYY-MM-DD'),
  division: null,
  limit: 30,
  dutyType: null,
  skip: 0,
  minEvents: null,
  maxEvents: null,
  driverIds: null,
  sort: 'desc',
  includeCustomTripKeys: false
}

export const defaultTripOptsV2: IReqFleetTripsV2 = {
  after: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  before: moment().format('YYYY-MM-DD'),
  division: null,
  limit: 30,
  dutyType: null,
  skip: 0,
  minEvents: null,
  maxEvents: null,
  driverIds: null,
  assetId: null,
  sort: 'desc',
}


