import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vex-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent implements OnInit {

  @Input() busy: boolean = false;
  @Output() click: EventEmitter<Event> = new EventEmitter();

  public tippyProps = {
    theme: 'light'
  }
  
  constructor() { }

  ngOnInit(): void {
  }

  onClick(event: Event): void {
    this.click.emit(event)
  }

}
