import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy, Input  } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import { assertLeafType } from 'graphql';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Key } from 'protractor';
import { BehaviorSubject, Observable, scan, Subscription, take, of, switchMap, tap} from 'rxjs';
import { generateAggregateTripsOptions, IReqAggregateFleetTrips, IResAggregateFleetTripsRow } from 'src/app/concepts/api/lightmetrics/fleets/trips/get-aggregate-fleet-trips';
import { IDateRange } from 'src/app/concepts/utils/chrono-utils';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import { shortNames } from '../incident-summary/incident-summary.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FleetService } from 'src/app/providers/services/fleet-service';
import { UserService } from 'src/app/providers/services/user-service';
import { Console } from 'console';


@Component({
  selector: 'vex-incident-trend',
  templateUrl: './incident-trend.component.html',
  styleUrls: ['./incident-trend.component.scss']
})
export class IncidentTrendComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('card', { static: true }) private card:ElementRef
  private resizeObserver: ResizeObserver
  public options: ApexOptions

  public defaultOptions: ApexOptions = {
    labels: [],
    chart: {
      type: "bar",
      height: '100%',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    legend: {
      position: "right",
      offsetY: 40
    },
    colors: ["#E74C3C", "#8E44AD", "#3498DB", "#16A085", "#2ECC71", "#F39C12", "#D35400", "#ECF0F1", "#DFFF00", "#40E0D0", "#CCCCFF", "#34495E"],
    
  };

  private _dateRange: IDateRange
  @Input() set dateRange(value: IDateRange) {
    this._dateRange = value
    this._runQuery()
  }

  private _subs: Subscription[] = []
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  public chartData$ : Observable<ApexOptions>
  public numResourcesToLoad: number = 1
  public aggregateData: IResAggregateFleetTripsRow[]
  public xAxisGranularity = 'day'
  public language: string = this.translate.currentLang
  private user: string
  private _fleetType: boolean;
  private userFleetId: string

  constructor(
    public fdl: FleetDataLayer,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private fleetService: FleetService,
    private userService: UserService
  ) {
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  // private aggregateTripsDatasource = ((options: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> => {
  //   return this.fdl.getAggregateTrips(generateAggregateTripsOptions(options)).pipe(tap(res => {
  //     console.log('IncidentTrend: aggregate res', res)
  //   }))
  // });

  
  private aggregateTripsDatasource = ((options: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> => {
      return this.fdl.getAggregateTrips(generateAggregateTripsOptions(options)).pipe(tap(res => {
        console.log('IncidentTrend: aggregate res', res)
      }))
  });

  private aggregateTripsDatasourceV2 = ((options: IReqAggregateFleetTrips) : Observable<IResAggregateFleetTripsRow[]> => {
      return this.fdl.getAggregateViolationsV2(generateAggregateTripsOptions(options)).pipe(tap(res => {
        console.log('IncidentTrend: aggregate res', res)
      }))
  });

  ngAfterViewInit(): void {
    this.resizeObserver =
      new ResizeObserver(() => {
        this.chartData$ = of({...this.options, chart: {'height': this.card.nativeElement.offsetHeight}});
      });
    this.resizeObserver.observe(this.card.nativeElement);
  }

  private disconnectResizeObserver() {
    this.resizeObserver.unobserve(this.card.nativeElement);
    this.resizeObserver.disconnect();
  }

  async ngOnInit(): Promise<void> {
    this._runQuery()
  }

  ngOnDestroy() : void {
    this.disconnectResizeObserver();
  }

  private async _runQuery(): Promise<void> {
    this.numResourcesToLoad = 1

    if(this._subs?.length > 0) {
      this._subs.forEach(sub => sub.unsubscribe())
      this._subs = []
    }

    await this.fleetService.setFleet();
    const fleetType = await this.fleetService.fleetType$.pipe(take(1)).toPromise();
    this._fleetType = fleetType;
    console.log('fleet type2', this._fleetType);

    this._increment = new BehaviorSubject<number>(0);
    this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    this._subs.push(this.loaded.subscribe(loadedCounter => {
      if(loadedCounter === this.numResourcesToLoad) {
        if(this._fleetType){
          this._extractAggregateTableDataV2()
        }else{
          this._extractAggregateTableData()
        }
      }
    }))

    const granularity =
    this.calcGranularity(DateTime.fromFormat(this._dateRange.from, 'yyyy-LL-dd'),
      DateTime.fromFormat(this._dateRange.to, 'yyyy-LL-dd'))
    console.log('SELECTED GRANULARITY', granularity)
    if(this._fleetType){
      this.aggregateTripsDatasourceV2({
        groupBy: granularity as any,
        after: this._dateRange.from,
        before: this._dateRange.to,
        limit: 500,
        skip: 0,
        division: '',
        dutyType: 'light',
        sort: 'asc'
      }).pipe(take(1)).subscribe(res => {
      this.aggregateData = res
      console.log('aggregateData11', this.aggregateData)
      this._extractAggregateTableDataV2()
      this._increment.next(1)
    })
    }else{
      const subscription = granularity === 'days'
      ? this.getDays()
      : granularity === 'weeks'
        ? this.getWeeks()
        : granularity === 'months'
          ? this.getMonths()
          : this.getYears()


    subscription.pipe(take(1)).subscribe(res => {
      this.aggregateData = res
      console.log('aggregateData', this.aggregateData)
      this._increment.next(1)
    })
    }
    
  }

  private calcGranularity(after: DateTime, before: DateTime) : string {
    if(before.diff(after, 'days').days > 28) {
      if(before.diff(after, 'weeks').weeks > 16) {
        if(before.diff(after, 'months').months > 12) {
          return 'years' // may have to just roll it down to arbitrary dates at some point :D
        } else return 'months'
      } else return 'weeks'
    }
    return 'days'
  }

  // TODO - Reverse the logic, months, if not enough, weeks, if not enough, days

  private getDays() : Observable<IResAggregateFleetTripsRow[]> {
    this.fdl.addCache<IResAggregateFleetTripsRow>('aggregate.trips.days', this.aggregateTripsDatasource, {
      groupBy: 'day',
      after: this._dateRange.from,
      before: this._dateRange.to,
      limit: 500,
      skip: 0
    })
    const cache = this.fdl.getCacheType<IResAggregateFleetTripsRow>('aggregate.trips.days')
    return cache
      .getPage(0)
      .pipe(
        take(1)
      )

  }

  private getWeeks() : Observable<IResAggregateFleetTripsRow[]> {
    this.xAxisGranularity = 'week'
    this.fdl.addCache<IResAggregateFleetTripsRow>('aggregate.trips.weeks', this.aggregateTripsDatasource, {
      groupBy: 'week',
      after: this._dateRange.from,
      before: this._dateRange.to,
      limit: 1000000,
      skip: 0
    })
    const cache = this.fdl.getCacheType<IResAggregateFleetTripsRow>('aggregate.trips.weeks')
    return cache
      .getPage(0)
      .pipe(
        take(1)
      )

  }

  private getMonths() : Observable<IResAggregateFleetTripsRow[]> {
    this.xAxisGranularity = 'month'
    this.fdl.addCache<IResAggregateFleetTripsRow>('aggregate.trips.months', this.aggregateTripsDatasource, {
      groupBy: 'month',
      after: this._dateRange.from,
      before: this._dateRange.to,
      limit: 1000000,
      skip: 0
    })
    const cache = this.fdl.getCacheType<IResAggregateFleetTripsRow>('aggregate.trips.months')
    return cache
      .getPage(0).pipe(take(1))
  }

  private getYears() : Observable<IResAggregateFleetTripsRow[]> {
    this.xAxisGranularity = 'year'
    this.fdl.addCache<IResAggregateFleetTripsRow>('aggregate.trips.years', this.aggregateTripsDatasource, {
      groupBy: 'year',
      after: this._dateRange.from,
      before: this._dateRange.to,
      limit: 100,
      skip: 0
    })
    const cache = this.fdl.getCacheType<IResAggregateFleetTripsRow>('aggregate.trips.years')
    return cache
      .getPage(0).pipe(take(1))
  }

  private _extractAggregateTableData() {
    console.log('EXTRACTING DATA')
    // this.incidentsTableData$ = of(this.options)

    let index = 0
    const filteredData = this.aggregateData
      .filter(row => moment(row.key).isBetween(moment(this._dateRange.from), moment(this._dateRange.to)))
    const aggregate = filteredData
      .reduce((acc, row) => {
        // add logic to checkj if key is in acc if not add it with the value in an array [value]
        // else push the value to the exiisting keys array
        // this requires rewriting... what we need to do is merge the dupliates AFTER we generate the chart
        console.log('row.key', row.key, 'from', this._dateRange.from, 'to', this._dateRange.to)
        Object.keys(row.value.eventCount).forEach(key => {
          const shortName = shortNames[key] ? this.translate.instant(shortNames[key]) : shortNames[key];
          if(!(shortName in acc)) {
            acc[shortName] = new Array(filteredData.length).fill(0)
          }
          acc[shortName][index] += row.value.eventCount[key] || 0
        })
        index++
        return acc
      }, {})
      
    // get rid of [0,0,0,0] arrays
    const toDelete = Object.keys(aggregate).filter(key => JSON.stringify(aggregate[key]) === "[0,0,0,0]")
    toDelete.forEach(key => delete aggregate[key])

    // merge short names ()
    
    console.log('toDelete', toDelete)
  
    console.log('extraction aggy', aggregate)

    const series = Object.keys(aggregate).filter(key => key !== this.translate.instant('shortNames.total')).filter(key => key !== 'undefined').map(key => ({ name: key, data: aggregate[key]})).sort((a,b) => b.data - a.data).sort((a,b) => a.name.localeCompare(b.name))
    const xaxis = { categories: filteredData.map(row => row.key) }

    xaxis['categories'] = xaxis['categories'].map(date => {
      return this.datePipe.transform( date, 'dd-MM-YYYY');
    });

    console.log('series', series)
    console.log('xaxis', xaxis['categories'])

    this.options = {...this.defaultOptions, series, xaxis}
    this.chartData$ = of(this.options)
    this._increment.next(1)
  }


  private _extractAggregateTableDataV2() {
    console.log('EXTRACTING DATA')
    // this.incidentsTableData$ = of(this.options)

    let index = 0
    console.log('aggregateData22', this.aggregateData)
    this.aggregateData.sort((a, b) => a.date.localeCompare(b.date));
    const filteredData = this.aggregateData
      .filter(row => moment(row.date).isBetween(moment(this._dateRange.from), moment(this._dateRange.to)))
      console.log('filteredData', filteredData)
    const aggregate = filteredData
      .reduce((acc, row) => {
        // add logic to checkj if key is in acc if not add it with the value in an array [value]
        // else push the value to the exiisting keys array
        // this requires rewriting... what we need to do is merge the dupliates AFTER we generate the chart
        console.log('row.key', row.date, 'from', this._dateRange.from, 'to', this._dateRange.to)
        Object.keys(row.eventCount).forEach(key => {
          const shortName = shortNames[key] ? this.translate.instant(shortNames[key]) : shortNames[key];
          if(!(shortName in acc)) {
            acc[shortName] = new Array(filteredData.length).fill(0)
          }
          acc[shortName][index] += row.eventCount[key] || 0
        })
        index++
        return acc
      }, {})
      
    // get rid of [0,0,0,0] arrays
    const toDelete = Object.keys(aggregate).filter(key => JSON.stringify(aggregate[key]) === "[0,0,0,0]")
    toDelete.forEach(key => delete aggregate[key])

    // merge short names ()
    
    console.log('toDelete', toDelete)
  
    console.log('extraction aggy', aggregate)

    const series = Object.keys(aggregate).filter(key => key !== this.translate.instant('shortNames.total')).filter(key => key !== 'undefined').map(key => ({ name: key, data: aggregate[key]})).sort((a,b) => b.data - a.data).sort((a,b) => a.name.localeCompare(b.name))
    const xaxis = { categories: filteredData.map(row => row.date) }
    console.log('xaxis', xaxis)

    xaxis['categories'] = xaxis['categories'].map(date => {
      return this.datePipe.transform( date, 'dd-MM-YYYY');
    });

    console.log('series', series)
    console.log('xaxis', xaxis['categories'])

    this.options = {...this.defaultOptions, series, xaxis}
    this.chartData$ = of(this.options)
    this._increment.next(1)
  }
}
