import moment from "moment"
import { Moment } from "moment"

export enum EDuration {
  custom = 'custom',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year'
}

export const momentFromDuration = (duration: EDuration) : Moment => {
  switch(duration) {
    case EDuration.week: return moment().subtract(1, 'week')
    case EDuration.month: return moment().subtract(1, 'month')
    case EDuration.quarter: return moment().subtract(3, 'month')
    case EDuration.year: return moment().subtract(1, 'year')
  }
  console.warn('Error trying to make moment from bad EDuration.')
}

export interface IDateRangeData {
  [key: string] : EDuration
}

export interface IDurationPickerData {
  [key: string]: IDateRangeData
}

export interface IDurationPickerDefault {
  [key: string]: EDuration
}

export const durationPickerData: IDurationPickerData = {
  'home': {
    'chornoUtils.week': EDuration.week,
    'chornoUtils.month': EDuration.month,
    'chornoUtils.quarter': EDuration.quarter,
    'chornoUtils.year': EDuration.year
  },
}

export const durationPickerDefaults: IDurationPickerDefault = {
  'home': EDuration.week
}

export const eDurationToString = (e: EDuration): string => Object.keys(EDuration).find(r => EDuration[r] === e)

export const getDurationPickerData = (page: string): IDateRangeData => durationPickerData[page]

export const getDurationDefault = (page: string): EDuration => durationPickerDefaults[page]

export const getLabelData = (data: IDateRangeData) : string[] => {
  return Object.keys(data)
}

export const SECONDS_TO_HOURS = 3600
export const STALE_CACHE = 5

export interface IDateRange {
  from: string,
  to: string
}
