import { getParams } from "../generic/utils"

export interface IReqDriverEventDetails {
  includeInertialSensorData?: boolean // TODO note in postman enabling this causes a 500 error as of 29/08/2022
}

export const getParamsDriverEventDetails = (options: IReqDriverEventDetails) : string => {
  const p = getParams(options)
  return p
}

export const generateEventDetailsOptions = (options: IReqDriverEventDetails) => {
  return {...defaultReqDriverEventDetails, ...options}
}

export const defaultReqDriverEventDetails: IReqDriverEventDetails = {
  includeInertialSensorData: false,
}

// Generated by https://quicktype.io

export interface IResDriverEventDetails {
  eventType:                      string;
  tripId:                         string;
  eventIndex:                     number;
  longitude:                      number;
  latitude:                       number;
  bearing:                        number;
  accuracy:                       number;
  speed:                          number;
  timestampUTC:                   string;
  timezoneOffset:                 number;
  timestamp:                      string;
  fleetId:                        string;
  driverId:                       string;
  driverName:                     string;
  challengeRaised:                boolean;
  challengeResolved:              boolean;
  challengeAccepted:              boolean;
  timeZoneId:                     string;
  mediaFiles:                     MediaFile[];
  asset:                          Asset;
  device:                         Device;
  tailgatingMetricValue:          number;
  severity:                       number;
  bookmark:                       boolean;
  coachingCompleted:              boolean;
  tags:                           any[];
  eventVideoFile:                 string;
  eventVideoFilename:             string;
  videoDetails:                   VideoDetails;
  eventVideoFilenameUploadStatus: EventVideoFilenameUploadStatus;
}

export interface Asset {
  assetId: string;
}

export interface Device {
  deviceId: string;
}

export interface EventVideoFilenameUploadStatus {
  status:          string;
  uploadTimestamp: string;
}

export interface MediaFile {
  mediaFileIndex: number;
  mediaFile:      string;
  source:         string;
  videoDetails:   VideoDetails;
  uploadStats:    EventVideoFilenameUploadStatus;
}

export interface VideoDetails {
  videoWidth:     number;
  videoHeight:    number;
  videoBitrate:   number;
  includesPII:    boolean;
  videoQuality:   number;
  videoType:      string;
  videoPiPType:   string;
  codec_type:     string;
  videoFrameRate: number;
  piiSubframe:    PiiSubframe;
}

export interface PiiSubframe {
  topLeftX: number;
  topLeftY: number;
  width:    number;
  height:   number;
}
