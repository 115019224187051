import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import { VikingAPIService } from 'src/app/providers/services/viking-api-service';

@Component({
  selector: 'vex-asset-id-selector',
  templateUrl: './asset-id-selector.component.html',
  styleUrls: ['./asset-id-selector.component.scss']
})
export class AssetIDSelectorComponent implements OnInit {
  @Input() selectedAssetId: string
  @Input() fleetType: boolean
  @Output() onAssetChange = new EventEmitter<any>();
  public allFleetAssets: string[] = []

  constructor(
    private vapi: VikingAPIService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.fleetType)
      this.vapi.listVikingAssetsV2().pipe(take(1)).subscribe({
        next: (assets) => {
          let temp = assets
          .map(asset => asset.assetId)
          .sort((a,b) => a.localeCompare(b))
          this.allFleetAssets = temp
        }
      })
    else
      this.vapi.getAllFleetAssets().then((assets) => {
        let temp = assets
        .sort((a,b) => a.localeCompare(b))
        this.allFleetAssets = temp
      })
  }
  
  public async onChange(assetId: string) {
    this.selectedAssetId = assetId;
    this.onAssetChange.emit(assetId);
  }
}
