<div fxFlex fxLayout="column" fxLayoutAlign="stretch start" class="section-card">
  <div class="section-header" fxLayout="row" fxLayoutAlign="space-between start" fxFullWidth>
    <h3 class="title card-title">{{ 'incidentSummary.incidentSummary' | translate}}</h3>
  </div>
  <mat-card fxFlex [style.width]="'100%'" [style.height]="'100%'" fxLayout="row">
    <div #card fxFill>
      <vex-widget-large-chart [options]="incidentsTableData$ | async"
      class="sm:col-span-2"></vex-widget-large-chart>
    </div>
  </mat-card>
</div>
