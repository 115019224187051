import { EIncidentType } from "../concepts/incidents/incident-types";
import { IIncident } from "../concepts/incidents/incidents";

export const incidentData: IIncident[] = [
  {
    id: "1",
    tripId: "1",
    type: 'Distracted-Driving',
    thumbURI: "/assets/img/placeholders/incident1.png",
    videoURI: "",
    date: "06/23/2022 17:36:31",
    driverId: "POLY0001"
  }, {
    id: "2",
    tripId: "1",
    type: 'Forward-Collision-Warning',
    thumbURI: "/assets/img/placeholders/incident2.png",
    videoURI: "",
    date: "06/26/2022 10:34:49",
    driverId: "POLY0001"
  }, {
    id: "3",
    tripId: "1",
    type: 'Harsh-Acceleration',
    thumbURI: "/assets/img/placeholders/incident3.png",
    videoURI: "",
    date: "06/26/2022 10:48:17",
    driverId: "POLY0002"
  }, {
    id: "4",
    tripId: "1",
    type: 'Harsh-Braking',
    thumbURI: "/assets/img/placeholders/incident4.png",
    videoURI: "",
    date: "06/17/2022 22:43:31",
    driverId: "POLY0001"
  }
]
