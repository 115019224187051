import { EUserType } from "./user-type"

export interface TopDriverResult {
  name: string
  incidents: string
  distance: string
}

export interface IUserTypePickerData {
  [key: string]: EUserType
}

const userTypePickerData: IUserTypePickerData = {
  'Driver': EUserType.Driver,
  'Installer': EUserType.Installer,
}

export const getUserTypePickerData = () : IUserTypePickerData => userTypePickerData

export const getLabelData = () : string[] => {
  return Object.keys(userTypePickerData).map(k => k.match(/[A-Z]+[a-z]*|[0-9]*/g).join(" "))
}

export const getLabel = (type: EUserType|string): string => (typeof type === "string" ? type : EUserType[type]).match(/[A-Z]+[a-z]*|[0-9]*/g).join(" ")
export const getUserTypesAsKeys = () => Object.keys(EUserType).filter(x => !(parseInt(x) >= 0))
