import moment from "moment"
import { ICacheOptions } from "src/app/providers/data-layers/fleet-data-layer"
import { getParams } from "../generic/utils"

export interface IReqAggregateFleetTrips {
  groupBy: 'day' | 'month' | 'year'
  after: string | null
  before: string | null
  division: string | null
  limit: number | null
  dutyType: 'light' | 'medium' | 'heavy' | null
  skip: number | null // use this for pagination
  sort: 'asc' | 'desc'
  driverId?: string | null
  assetId?: string | null
}

export const defaultAggregateTripOpts: IReqAggregateFleetTrips = {
  groupBy: null,
  after: null,
  before: null,
  division: null,
  limit: null,
  dutyType: null,
  skip: null,
  sort: 'asc',
}

export const getParamsAggregateFleetTrips = (options: IReqAggregateFleetTrips) : string => {
  return getParams(options)
}

export const generateAggregateTripsOptions = (options?: IReqAggregateFleetTrips) => {
  return {...defaultAggregateTripOpts, ...options}
}

// Generated by https://quicktype.io

export interface IResAggregateFleetTrips {
  limit: number;
  skip:  number;
  rows:  IResAggregateFleetTripsRow[];
}

export interface IResAggregateFleetTripsRow {
  eventCount: {}
  date:  string;
  key:   string;
  value: FleetTripsAggregateValue;
}

export interface FleetTripsAggregateValue {
  tripDistanceInkm: number
  totalViolations: number
  ViolationsCount: number;
  totalTrips: any 
  durationInms: any
  tripDistance: number;
  eventCount:   { [key: string]: number };
  tripCount:    number;
  tripDuration: number;
  score:        number;
}
