import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { eventsPath, liveStreamPath } from 'src/app/concepts/utils/api/api-utils';
import { environment } from 'src/environments/environment';
import { getParamsMessageEventStream, getParamsResetEventStream, IReqMessageEventStream, IReqResetEventStream } from 'src/app/concepts/api/lightmetrics/events/event-stream';
import { getParamsRequestLiveStream, getParamsStopLiveStream, IReqRequestLiveStream, IReqStopLiveStream, IResRequestLiveStream } from 'src/app/concepts/api/lightmetrics/live-streaming/live-stream-requests';

@Injectable({
  providedIn: 'root',
})
export class LightmetricsService {

  constructor(
    // private _s3: S3Service,
    // private _vikingService: VikingAPIService,
    // private _apiChooserService: ApiChooserService
  ) {

  }

  // EVERYTHING OTHER THAN EVENTS HAS BEEN DEPRECATED
  // I AM LEAVING THEM COMMENTED SO THEY ARE AVAILABLE
  // SHOULD WE NEED TO TALK TO LM ~JR

  // private async _getFleets(path: string, params?:string) : Promise<any> {
  //   console.log('_getFleets 1', path)
  //   const url = `${fleetsPath(path)}${params}`
  //   try {
  //     const res = await axios.get(url, {
  //       method: 'GET',
  //       headers: {
  //         // "X-Api-Key": environment.lightmetrics.apiKey,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     console.log('_getFleets 2 res', res)

  //     let json = await res.data
  //     console.log('_getFleets api response', json)
  //     if(json.s3RedirectUrl) {
  //       console.log('S3 Redirect found, getting file...', json.s3RedirectUrl)
  //       const s3Result = await this._s3.getPresignedURL(json.s3RedirectUrl)
  //       console.log('s3Result', s3Result)
  //       return s3Result
  //     }
  //     return json

  //   } catch(e) {
  //     console.warn('Error node.fetch:', e, 'from', url)
  //     return null
  //   }
  // }

  // public getConfiguration() : Observable<any> {
  //   if(this._apiChooserService.canViking('configuration')) {
  //     return this._vikingService.getConfiguration()
  //   }
  //   return from(this._getFleets('configuration'))
  // }

  // public getTrips(options: IReqFleetTrips) : Observable<any> {
  //   if(this._apiChooserService.canViking('trips')) {
  //     return this._vikingService.getTrips(options)
  //   }
  //   return from(this._getFleets('trips', getParamsFleetTrips(options)))
  // }

  // public getViolations(options: IReqFleetViolations) : Observable<any> {
  //   if(this._apiChooserService.canViking('violations')) {
  //     return this._vikingService.getViolations(options)
  //   }
  //   return from(this._getFleets('violations', getParamsFleetViolations(options)))
  // }

  // public getDrivers(options: IReqFleetDrivers) : Observable<any> {
  //   console.log('**DRIVERS** LMS options', options)

  //   if(this._apiChooserService.canViking('drivers')) {
  //     return from(this._vikingService.getDrivers(options))
  //   }
  //   return from(this._getFleets('drivers', getParamsFleetDrivers(options)))
  // }

  // public getAssets(options: IReqFleetAssets) : Observable<any> {
  //   if(this._apiChooserService.canViking('assets')) {
  //     return this._vikingService.getAssets(options)
  //   }
  //   return from(this._getFleets('assets', getParamsFleetAssets(options)))
  // }

  // public getDriverTrips(driverId: string, options: IReqDriverTrips) : Observable<any> {
  //   if(this._apiChooserService.canViking('drivers/:driverId/trips')) {
  //     return this._vikingService.getDriverTrips(driverId, options)
  //   }
  //   return from(this._getFleets(`drivers/${driverId}/trips`, getParamsDriverTrips(options)))
  // }

  // reusing fleet data structures as the results look the same to me
  // public getDriverViolations(driverId: string, options: IReqFleetViolations) : Observable<any> {
  //   console.log('LMS: getDriverViolations driverId', driverId)
  //   if(this._apiChooserService.canViking('drivers/:driverId/violations')) {
  //     return this._vikingService.getDriverViolations(driverId, options)
  //   }
  //   return from(this._getFleets(`drivers/${driverId}/violations`, getParamsFleetViolations(options)))
  // }

  // reusing fleet data structures as the results look the same to me
  // public getAssetViolations(assetId: string, options: IReqFleetViolations) : Observable<any> {
  //   console.log('LMS: getAssetViolations assetId', assetId)
  //   if(this._apiChooserService.canViking('assets/:assetId/violations')) {
  //     return this._vikingService.getAssetViolations(assetId, options)
  //   }
  //   return from(this._getFleets(`assets/${assetId}/violations`, getParamsFleetViolations(options)))
  // }

  // public getAggregateFleetTrips(options: IReqAggregateFleetTrips) : Observable<any> {
  //   if(this._apiChooserService.canViking('aggregate')) {
  //     return this._vikingService.getAggregateFleetTrips(options)
  //   }
  //   return from(this._getFleets('aggregate', getParamsAggregateFleetTrips(options)))
  // }

  // public getAggregateDriverTrips(driverId: string, options: IReqAggregateDriverTrips) : Observable<any> {
  //   if(this._apiChooserService.canViking('drivers/:driverId/aggregate')) {
  //     return this._vikingService.getAggregateDriverTrips(driverId, options)
  //   }
  //   return from(this._getFleets(`drivers/${driverId}/aggregate`, getParamsAggregateDriverTrips(options)))
  // }

  // public getAggregateAssetTrips(assetId: string, options: IReqAggregateAssetTrips) : Observable<any> {
  //   if(this._apiChooserService.canViking('assets/:assetId/aggregate')) {
  //     return this._vikingService.getAggregateAssetTrips(assetId, options)
  //   }
  //   return from(this._getFleets(`assets/${assetId}/aggregate`, getParamsAggregateDriverTrips(options))) // not typo params same as drivers
  // }

  // public getFleetCoaching(options: IReqFleetCoaching) : Observable<any> {
  //   if(this._apiChooserService.canViking('coaching-events')) {
  //     return this._vikingService.getFleetCoaching(options)
  //   }
  //   return from(this._getFleets('coaching-events', getParamsFleetCoaching(options)))
  // }

  // public getDriverTripDetails(driverId: string, tripId: string, options: IReqDriverTripDetails) : Observable<any> {
  //   if(this._apiChooserService.canViking('drivers/:driverId/trips/:tripId')) {
  //     return from(this._vikingService.getDriverTripDetails(driverId, tripId, options))
  //   }
  //   return from(this._getFleets(`drivers/${driverId}/trips/${tripId}`, getParamsDriverTripDetails(options)))
  // }

  // public getDriverEventDetails(driverId: string, tripId: string, eventIndex: number, options: IReqDriverEventDetails) : Observable<any> {
  //   if(this._apiChooserService.canViking('drivers/:driverId/trips/:tripId/events/:eventIndex')) {
  //     return this._vikingService.getDriverEventDetails(driverId, tripId, eventIndex, options)
  //   }
  //   return from(this._getFleets(`drivers/${driverId}/trips/${tripId}/events/${eventIndex}`, getParamsDriverEventDetails(options)))
  // }

  // public getOngoingTrips(options: IReqOngoingTrips) : Observable<any> {
  //   if(this._apiChooserService.canViking('ongoing-trips')) {
  //     return this._vikingService.getOngoingTrips(options)
  //   }
  //   return from(this._getFleets('ongoing-trips', getParamsOngoingTrips(options)))
  // }

  public getEventStream(options: IReqMessageEventStream) : Observable<any> {
    const url = `${eventsPath('messages')}${getParamsMessageEventStream(options)}`
    try {
      return from(fetch(url, {
        method: 'GET',
        headers: {
          "X-Api-Key": environment.lightmetrics.apiKey,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json()))
    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
    }
  }

  public resetEventStream(options: IReqResetEventStream) : Observable<any> {
    const url = `${eventsPath('reset-position')}${getParamsResetEventStream(options)}`
    try {
      return from(fetch(url, {
        method: 'POST',
        headers: {
          "X-Api-Key": environment.lightmetrics.apiKey,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json()))
    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
    }
  }

  public async requestLiveStream(options: IReqRequestLiveStream) : Promise<IResRequestLiveStream> {
    const url = `${liveStreamPath('create-stream-request')}${getParamsRequestLiveStream(options)}`
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          "X-Api-Key": environment.lightmetrics.apiKey,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
    }
  }

  public async stopLiveStream(options: IReqStopLiveStream) : Promise<Observable<any>> {
    const url = `${liveStreamPath('stop-stream')}${getParamsStopLiveStream(options)}`
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          "X-Api-Key": environment.lightmetrics.apiKey,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
    } catch(e) {
      console.warn('Error node.fetch:', e, 'from', url)
    }
  }
}
