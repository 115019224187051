import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { RefreshButtonComponent } from './refresh-button.component';

@NgModule({
  declarations: [RefreshButtonComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgxTippyModule
  ],
  exports: [RefreshButtonComponent]
})
export class RefreshButtonModule {
}
