import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { UserTypeSelectorModule } from 'src/app/components/user-type-selector/user-type-selector.module';
import { AddDriverDialogComponent } from './add-driver-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AssetIDSelectorModule } from 'src/app/components/asset-id-selector/asset-id-selector.module';

@NgModule({
  declarations: [AddDriverDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    FlexLayoutModule,
    UserTypeSelectorModule,
    AssetIDSelectorModule
  ]
})
export class AddDriverDialogModule {
}
