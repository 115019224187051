import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map, skipWhile } from 'rxjs/operators';
import { CognitoService } from '../services/cognito-service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public authService: CognitoService, public router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    console.log('canActivate', route)
    return from(this.authService.isAuthenticated()).pipe(
      skipWhile((state) => state === null),
      map((state) => {
        if (!state) {
          this.router
            .navigate(['/login'])
            .then(() => console.warn('Unable to activate the requested route: ' + route.url));
        }
        return state;
      })
    );
  }
}
