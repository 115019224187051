import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { CoachingRequiredResult } from 'src/app/concepts/coaching/coaching';
import { coachingRequiredData } from 'src/app/mock-data/coaching-required-data';

@Component({
  selector: 'vex-require-coaching',
  templateUrl: './require-coaching.component.html',
  styleUrls: ['./require-coaching.component.scss']
})
export class RequireCoachingComponent implements OnInit {

  public tableColumns: TableColumn<CoachingRequiredResult>[] = [
    {
      label: 'Driver',
      property: 'name',
      type: 'text'
    },
    {
      label: 'Incidents / 100 miles	',
      property: 'incidents',
      type: 'text',
      cssClasses: ['font-medium']
    },
    {
      label: 'Distance (mi)',
      property: 'distance',
      type: 'text'
    },
    {
      label: 'Risk',
      property: 'risk',
      type: 'text'
    }
  ];
  tableData = coachingRequiredData;

  constructor() { }

  ngOnInit(): void {
  }

}
