<div fxLayout="column" class="container trip-details" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
  <mat-card fxFlex="1 1 10%" fxFlex.lt-md="0 1 auto" fxLayoutAlign="start start" class="trip-details-header">
    <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center start" fxLayoutGap.lt-md="40px grid" class="header-container" [style.height]="'100%'">
      <div fxFlex="1 1 auto" class="left" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.lt-md="20px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
        <!-- <mat-icon fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="icon-text">keyboard_backspace</mat-icon> -->
        <div fxLayoutAlign="center center" fxLayoutAlign.lt-md="start start" class="directions-wrapper">
          <div fxLayout="column" class="directions-icon">
            <mat-icon class="green">circle</mat-icon>
            <div fxLayout="row" fxLayoutAlign="center start" class="line-wrapper">
              <div class="line"></div>
            </div>
            <mat-icon class="red">circle</mat-icon>
          </div>
        </div>
        <div class="2-line-text">
          <p class="header-text">{{ 'tripDetails.driver' | translate}}</p>
          <p class="header-text header-text-value">{{(polyHeader$ | async)?.driverName}}</p>
        </div>
      </div>
      <div fxFlex="1 1 auto" fxFlex.lt-md="1 1 auto" class="right" fxLayout="row-reverse" fxLayout.lt-md="row wrap" fxLayoutGap="20px" fxLayoutGap.md="20px grid" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
        <!-- <mat-icon fxFlexOrder.lt-sm="6" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="icon-text">filter_alt</mat-icon> -->
        <div fxFlexOrder.lt-sm="5" class="2-line-text">
          <p class="header-text">{{ 'tripDetails.incidents' | translate}}</p>
          <p class="header-text header-text-value">{{(polyHeader$ | async)?.incidentCount}}</p>
        </div>
        <div fxFlexOrder.lt-sm="4" class="2-line-text">
          <p class="header-text">{{ 'tripDetails.distance' | translate}}</p>
          <p class="header-text header-text-value">{{(polyHeader$ | async)?.distance}}</p>
        </div>
        <div fxFlexOrder.lt-sm="3" class="2-line-text">
          <p class="header-text">{{ 'tripDetails.duration' | translate}}</p>
          <p class="header-text header-text-value">{{(polyHeader$ | async)?.duration}}</p>
        </div>
        <div fxFlexOrder.lt-sm="2" class="2-line-text">
          <p class="header-text">{{ 'tripDetails.startDateTime' | translate}}</p>
          <p class="header-text header-text-value">{{(polyHeader$ | async)?.time}}</p>
        </div>
        <div fxFlexOrder.lt-sm="1" class="2-line-text">
          <p class="header-text">{{ 'tripDetails.assetId' | translate}}</p>
          <p class="header-text header-text-value">{{(polyHeader$ | async)?.assetId}}</p>
        </div>
        <!-- <div class="2-line-text"></div>
        <div class="2-line-text"></div>
        <div class="2-line-text"></div>
        <div class="2-line-text"></div> -->
      </div>
    </div>
  </mat-card>
  <mat-tab-group>
    <!--<mat-tab label=" {{ 'tripDetails.map' | translate}}">-->
      <mat-tab label="{{'stopsTable.events' | translate}}">
        <div fxFlex fxLayout="row" fxFlexFill="true" fxLayoutGap="40px" fxLayout.lt.md="column" fxLayout.lt-md="column" class="content">
          <div fxFlex class="map">
            <google-map #map width="100%" height="100%" *ngIf="polyTrip$ && polyMarkers$">
              <map-polyline [path]="(polyTrip$ | async)?.route?.locationData"></map-polyline>
              <map-marker #marker="mapMarker" *ngFor="let event of (polyMarkers$ | async) || []" (mapClick)="markerClicked(event, marker)" [position]="event.position" [options]="event.options"></map-marker>
              <map-info-window [style.color]="'black'" >
                <div [innerHTML]="infoContent"></div>
              </map-info-window>
            </google-map>
          </div>
          <mat-card class="details">
            <div class="custom-mat-div" fxLayout="row" fxLayoutAlign="center center">
              <mat-button-toggle-group (change)="onIncidentTypeFilterChange($event.value)" class="button-group" #group="matButtonToggleGroup">
                <mat-button-toggle disableRipple="true" checked="true" value="all">
                  <span class="title">{{ 'tripDetails.all' | translate}}</span>
                </mat-button-toggle>
                <mat-button-toggle disableRipple="true" value="low">
                  <span class="dot-icon low"></span>
                  <span class="title">{{ 'tripDetails.low' | translate}}</span>
                </mat-button-toggle>
                <mat-button-toggle disableRipple="true" value="medium">
                  <span class="dot-icon medium"></span>
                  <span class="title">{{ 'tripDetails.medium' | translate}}</span>
                </mat-button-toggle>
                <mat-button-toggle disableRipple="true" value="high">
                  <span class="dot-icon high"></span>
                  <span class="title">{{ 'tripDetails.high' | translate}}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="custom-mat-div" fxLayout="row" fxLayoutAlign="center center">
              <div class="table">
                <!-- <vex-widget-table [columns]="tableColumns"
                  [data]="tableData"
                  class="table">
                </vex-widget-table> -->
                <vex-trip-details-incidents-table [tableData]="polyTableData$ | async" [loaded]="tableDataLoaded$ | async"></vex-trip-details-incidents-table>
              </div>
            </div>
          </mat-card>
        </div>
      </mat-tab>
    <!-- <mat-tab label=" {{ 'tripDetails.incidents' | translate}}"> -->
      <mat-tab label="{{'stopsTable.behaviour' | translate}}">
        <div fxFlex fxLayout="row" fxFlexFill="true" fxLayoutGap="40px" fxLayout.lt.md="column" fxLayout.lt-md="column" class="content">
          <div fxFlex class="stopsMap">
            <google-map #stopsMap width="100%" height="100%" *ngIf="polyTrip$ && stopsPolyMarkers$" >
              <map-polyline [path]="(polyTrip$ | async)?.route?.locationData"></map-polyline>
              <map-marker #marker="mapMarker" *ngFor="let event of (stopsPolyMarkers$ | async) || []" (mapClick)="markerClicked(event, marker)" [position]="event.position" [options]="event.options"></map-marker>
              <map-info-window [style.color]="'black'" >
                <div [innerHTML]="infoContent"></div>
              </map-info-window>
            </google-map>
          </div>
          <mat-card class="details">
            <label>{{ 'stopsTable.showStopsMoreThan' | translate}}</label>
              <select [(ngModel)]="selectedOption" (ngModelChange)="onSelectionChange(selectedOption)">
                <option *ngFor="let option of dropdownOptions" [value]="option.value">{{ option.label }}</option>
              </select>
              <div style="margin-bottom: 10px;"> <!-- Add margin bottom to create space -->
            </div>
            <mat-card-content>
              <vex-trip-details-stops-table [tableData]="stopsPolyTableData$ | async" [loaded]="stopsTableDataLoaded$ | async" #stopsTable></vex-trip-details-stops-table>
            </mat-card-content>
          </mat-card>                    
        </div>
      </mat-tab>
  </mat-tab-group>
</div>
