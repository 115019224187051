<div class="container">
  <div fxLayout="column" fxLayoutGap="40px">
    
    <!-- <h3 class="title card-title center" fxLayoutAlign="center">Live View</h3> -->
    <vex-live-view class="live-view"></vex-live-view>
    
    <div class="top-row" fxLayout="row-reverse" fxLayoutGap="10px" fxLayoutGap.xs="0" fxFullWidth [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
      <vex-duration-selector (change)="onDurationSelected($event)"></vex-duration-selector>
    </div>
    <vex-quad-stats [labels]="quadPanelLabels" [values$]="quadStatsValues$" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'"></vex-quad-stats>
    <!-- now the question is, do we connect the LM service with the repo?
      or do we pass the data layer? I say we pass the data layer. -->
    <vex-highlights [dateRange]="dateRange" [title]="'highlights.fleethighlights' | translate" [setDriverId]="'0'" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
    </vex-highlights>
    <div fxLayout="row" fxLayout.lt-md="column" fxFullWidth fxLayoutGap="20px">
      <!-- <vex-top-drivers [style.height]="'100%'" fxFlex="50"></vex-top-drivers> -->
    <vex-incident-trend [dateRange]="dateRange" class="trends" fxFlex="50" ></vex-incident-trend>
      
      <!-- TODO - All coaching features are removed for V1 -->
      <!-- <vex-require-coaching fxFlex="50"></vex-require-coaching> -->
      <!-- <vex-incident-summary [dateRange]="dateRange" class="summary" fxFlex="50"></vex-incident-summary> -->
      <vex-incident-summary [incidentData]="incidentSummaryData$ | async" class="summary" fxFlex="50" ></vex-incident-summary>
    </div>
    <h3 class="title card-title center" fxLayoutAlign="center" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">{{ 'home.topDrivers' | translate}}</h3>
    <div class="chart-row" fxLayout="row" fxLayout.lt-md="column" fxFlexFill fxFullWidth fxLayoutGap="20px" fxLayoutAlign="center">
      <!-- TODO - Moved incident summary above to replace coaching -->
      <!-- <vex-incident-summary class="summary" fxFlex="50"></vex-incident-summary> -->
      <div style="overflow-x:scroll;" [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'">
        <vex-top-drivers [dateRange]="dateRange" fxLayoutAlign="start" ></vex-top-drivers>
      </div>
    </div>   
  </div>

</div>
