<button [ngxTippy]="'Refresh data'" [tippyProps]="tippyProps" (click)="onClick($event)" fxFlexLayout="row" fxLayoutAlign="center center" mat-icon-button="" color="accent" class="refresh-button mat-accent" >
  <ng-container *ngIf="busy === false; else coachingSpinner">
    <span class="mat-button-wrapper" style="left: 50%">
      <mat-icon role="img" class="refresh-icon mat-icon notranslate material-icons mat-icon-no-color">refresh</mat-icon>
    </span>
    <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
    <span class="mat-button-focus-overlay"></span>
  </ng-container>
  <ng-template #coachingSpinner>
    <mat-progress-spinner diameter="20" role="progressbar" tabindex="-1" class="spinner mat-progress-spinner mat-accent mat-progress-spinner-indeterminate-animation" mode="indeterminate" >
      <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 12 12" >
        <circle cx="50%" cy="50%" r="5" style="animation-name: mat-progress-spinner-stroke-rotate-20; stroke-dasharray: 31.4159px; stroke-width: 10%;">
        </circle>
      </svg>
    </mat-progress-spinner>
  </ng-template>
</button>
