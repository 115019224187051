import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IAddDriverDialogData } from "src/app/concepts/user/driver/add-driver-dialog-data";

@Component({
  selector: 'add-driver-dialog',
  templateUrl: 'add-driver-dialog.component.html',
  styleUrls: ['./add-driver-dialog.component.scss']
})
export class AddDriverDialogComponent {

  public saved: IAddDriverDialogData

  constructor(
    public dialogRef: MatDialogRef<AddDriverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.saved = { ...data.data }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  public async onAssetChange(assetId: string) {
    this.saved.selectedAssetId = assetId;
  }

}
