import { Component, OnInit } from '@angular/core';
import { getLabelData, getUserTypePickerData, IUserTypePickerData } from 'src/app/concepts/user/driver/driver';

@Component({
  selector: 'vex-user-type-selector',
  templateUrl: './user-type-selector.component.html',
  styleUrls: ['./user-type-selector.component.scss']
})
export class UserTypeSelectorComponent implements OnInit {

  public labelData: IUserTypePickerData
  public selected: string

  constructor() { }

  ngOnInit(): void {
    this.labelData = getUserTypePickerData();
  }

  public labels = () : string[] => getLabelData();
}



