<div [style.overflow]="'hidden'">
  <div fxLayout="column" fxLayoutAlign="start" class="section-card">
    <div class="section-container" fxFlex fxLayout="column" fxFlexLayout="space-between">
      <div fxFlex class="section-header" fxLayout="row" fxLayoutAlign="space-between start" fxFullWidth>
        <h3 class="title card-title">Require Coaching</h3>
      </div>
      <div fxFlex class="section-content" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="0px" fxFullWidth>
        <vex-widget-table [columns]="tableColumns"
                    [data]="tableData"
                    class="sm:col-span-2">
        </vex-widget-table>
      </div>
    </div>
  </div>
</div>
