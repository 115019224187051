import { Component, OnInit } from '@angular/core';
import { from, Subscription } from 'rxjs';
import { CognitoService } from 'src/app/providers/services/cognito-service';
import { PopoverRef } from '../popover/popover-ref';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  private _subAuth: Subscription
  constructor(
    private readonly popoverRef: PopoverRef,
    private _authService: CognitoService
  ) { }

  ngOnInit(): void {
    this._subAuth = this._authService.authenticationSubject.subscribe({
      next: (result) => {
        console.log('Auth State from user menu', result)
      },
      error: (e) => {
        console.warn("Error: Authentication State Sub failed with:", e)
      }
    })
  }

  ngOnDestroy(): void {
    this._subAuth.unsubscribe()
  }

  signOut(): void {
    this._authService.signOut()
    this.close()
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }
}
