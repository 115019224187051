import { getParams } from "../generic/utils"
import moment from "moment"
import { ICacheOptions } from "src/app/providers/data-layers/fleet-data-layer"

export interface IReqFleetDrivers {
  dutyType: string | null
  after: string
  before: string
  division: string | null
}

export const getParamsFleetDrivers = (options: IReqFleetDrivers) : string => {
  return getParams(options)
}

export const defaultDriversOpts: IReqFleetDrivers = {
  dutyType: null,
  after: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  before: moment().format('YYYY-MM-DD'),
  division: null,
}

export const generateDriversOptions = (options?: IReqFleetDrivers) => {
  return {...defaultDriversOpts, ...options}
}

// Generated by https://quicktype.io

export interface IResFleetDrivers {
  rows: IResFleetDriversRow[];
}

export interface IResFleetDriversRow {
  driverId:     string;
  tripDistance: number;
  eventCount:   { [key: string]: number };
  tripCount:    number;
  tripDuration: number;
  score:        number;
  driverName?:  string;
}

export interface IResFleetAdminDrivers {
  rows: IResFleetAdminDriversRow[];
}

export interface IResFleetAdminDriversRow {
  driverId:     string;
  driverName?:  string;
	fleetId:      string;
  email?:       string;
	telephone?:   string;
	assetId?:     string;
	cameraInstalled?: boolean;
}

export interface ITopDrivers {
  name: string
  incidents: string
  distance: string
}
