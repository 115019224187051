<div class="top-row"
     fxLayout="row"
     fxLayoutAlign="space-between"
     fxLayoutGap="20px"
     fxLayoutGap.xs="0"
     fxFullWidth
>
  <ng-container *ngIf="(values$ | async); else loading">
    <ng-container *ngFor="let value of values$ | async; let i = index">
      <mat-card fxFlex="50" class="stats-card">
        <div class="card-title">{{labels?.[i]}}</div>
        <ng-container>
          <div class="card-text">{{value}}</div>
        </ng-container>
      </mat-card>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <ng-container *ngFor="let in of counter(4) ;let i = index">
      <mat-card fxFlex="50" class="stats-card">
        <div class="card-title">{{labels?.[i]}}</div>
        <!-- Honestly, the SVG values were trial and error, they make no sense. -->
        <content-loader [backgroundOpacity]="0" [foregroundOpacity]="0.1" [style]="{'height': '39px'}">
          <svg:rect x="0" y="28" rx="3" ry="3" width="100%" height="74" />
        </content-loader>
      </mat-card>
    </ng-container>
  </ng-template>

</div>
