import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { WidgetLargeChartModule } from 'src/@vex/components/widgets/widget-large-chart/widget-large-chart.module';
import { IncidentService } from 'src/app/providers/services/incident-service';
import { IncidentSelectorModule } from '../../incident-selector/incident-selector.module';
import { IncidentSummaryComponent } from './incident-summary.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [IncidentSummaryComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    IncidentSelectorModule,
    NgxTippyModule,
    WidgetLargeChartModule,
    TranslateModule
  ],
  providers: [
    IncidentService,
  ],
  exports: [
    IncidentSummaryComponent,
    TranslateModule
  ]
})
export class IncidentSummaryModule {
}
