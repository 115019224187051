import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { WidgetLargeChartModule } from 'src/@vex/components/widgets/widget-large-chart/widget-large-chart.module';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { AddDriverDialogModule } from 'src/app/dashboards/drivers/dialogs/add-driver/add-driver-dialog.module';
import { IncidentSelectorModule } from '../../incident-selector/incident-selector.module';
import { RefreshButtonModule } from '../../refresh-button/refresh-button.module';
import { LiveViewAssetsTableComponent } from './live-view-assets-table.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LiveViewAssetsTableComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    NgxTippyModule,
    WidgetLargeChartModule,
    RefreshButtonModule,
    MatSnackBarModule,
    MatTabsModule,
    IncidentSelectorModule,
    WidgetTableModule,
    AddDriverDialogModule,
    MatDialogModule,
    TranslateModule,
  ],
  exports: [
    LiveViewAssetsTableComponent,
    TranslateModule
  ]
})
export class LiveViewAssetsTableModule {
}
