import { Injectable } from "@angular/core";
import { Observable, from, take } from "rxjs";
import { environment } from "src/environments/environment";
import axios from "axios"

@Injectable({
  providedIn: 'root',
})
export class S3Service {

  constructor(
  ) {
    // this.getPresignedURL('https://lm-api-responses.s3.eu-west-2.amazonaws.com/api-1664547839772.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQZ7DZPZCCFJWCUZQ%2F20220930%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220930T142359Z&X-Amz-Expires=240&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMiJHMEUCIQCg7YbArMAT3mnUfvsOLDDE5iM1tPBTGoJAaX6XAe69TAIgbNzQxNAydkaWhtETeEzst7Er6p7Du%2Fh4QruyUyT5V7YqnwMIXxACGgwwNTU3NzU0MjgxNjQiDAfitBrwU1zLW5xdmSr8Arj%2BExU6QNCgEnQTw8vI1JaA5myt6cVUDtDfOcKaEo1a14zJtQiBqL6sFJ1FEemRiH498Mqrsbsz39kti%2Fyn%2BP85RJPMu%2BtTi5Hxiq4gGvq5CX7qCMLDbN5osC%2BYpMfKUgXBTfBKTGpJYNPjB6VgNJHm3H2nfNqqcmmLBV2PRu8n6O%2FkFRRqlWTIsGBqgpKC4hqcMz5RE4bKUK4kckul62Ln%2BzgLhmnIuRisxvI27wsA7TMgr1F1gYKg5EShIAHu8jDU306R%2FafyxcwoyBJ3Q8YlFIpgQpecmru%2F2qwn3ti35DR2Vy2H%2FYK81IPv8V%2Bqzlb46LHWHE%2BN5D2bBIjh9AL0wqx%2B5VPMGP7I%2FVwdgGFvEbz9Fo6NDTGOZOVs0eLintuPg7pNOInMp%2FG3sgwa4XgTlz4zFwvkmTPpgRq4JsOXxU4yuH6LjftH3M4WYstFrXPXea%2BznLT1bsGOQ13YMK65RH2jsKdbA3hz7U1toFmyhqc0BYmkcvyzBD3CMMr225kGOp0BkrtZm4vc2Sm9yWkdxcXo4jH2KXRWNBGtYr13zZ96CX7Mpo5hBED4wekdTC7vpOoTe7XLmZlXNWu0H8o2aj46J4YgIi0ZsDYRKhwhPeLFt1CB5IjRabfYMcBJqQkZPvS%2BVXmRItR1aEmWe82GEir1cWxYGtXOYUp7F1IgWZWf%2B37nxbgOVVDUz8CTpFfxb15GkGjGw5F7nsCyEi6dkA%3D%3D&X-Amz-Signature=fb93885b4e7b4220df5ebbe43302a728dc945abbecf61719400e339de4e6f6be&X-Amz-SignedHeaders=host')
    //   .pipe(take(1)).subscribe({
    //     next: res => {
    //       console.log('PRESIGNED RESPONSE', res)
    //     },
    //     error: err => {
    //       console.warn('PRESIGNED URL ERROR:', err)
    //     }
    //   })
    // axios.get(
    //   'https://lm-api-responses.s3.eu-west-2.amazonaws.com/api-1664551501971.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQZ7DZPZCALHCZIXA%2F20220930%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220930T152502Z&X-Amz-Expires=240&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMiJHMEUCIGGU1tH0sg5MEDyZxBP7ZlQBAsJ6Y0sfg4%2FC%2FL87I9PFAiEA7wltPq20gNYc%2B%2FluD66M2cvSOiyiDs%2BgovkF8KDYebsqnwMIYRACGgwwNTU3NzU0MjgxNjQiDA5tsnWJGT5wL4CrWCr8Ao%2BADWj2pERukpzc3w8A46x%2BakldA1UYOYETFPG6o0DEL3%2FhsEE9TmciaxUQhFxuXXZhlFkLlyNs6mCSlIZsh1d7xC69WBQLVYsayJTExhRRQdBy127g5hkOotiZ89OPC2D5GaOhgqblzQEAGrXok2TbyV6a8kAN0uBwAsk9mH8R0G2oyHrwdV9Lrog5xqEnnp6kJAiZChJXJOSxzwyXkDqfC4ZGRL98yjQO2lym4SPH%2BVQ1PmkPB1r4l6aLMi4QNE0dRYfin%2BUI3hfVuf6oA9ZNx7r49MBIn8Ng256bAfMZSkA%2BpujK5MA0kqqQBj0GsYS0Ak4bPel0AyTkXlYdmSJ3ZINtSrWQfHuT%2FHGorThzhygCE3jC3Vxi9rhNFXABjoBeCWCGpqoyWyeuo2hgIW1RR06EUpTyfnhJhx9CbMRQCbmL3d4TPkwSAbEjl2oRizJYlPVDR9MDHo5ieB1wmBm9AsbeJKjrsdkDFnEQ98ahIXXW8vxMgNRlGcqNMMuU3JkGOp0BvSoXP3UbuDHxJztNUguMGbpCOZTDkaJrAwK1k3ImwEwyEFOhLAKzprP7BQ8NBzLwjxlBqGIWqGF0174i42oUDDUPivV7kCA4tbcy94MNhKonJEH%2BQfFJGkGBUf5uMdtnsBQaJYrOrz4ImuGaoKCS3cTCOXuTYW5SCRecjOdZ8PLBI8nMCogYytdZeTF4sV1gZZe5ZxD%2FOWImcNhPiQ%3D%3D&X-Amz-Signature=6a75ff07fd378bfa01beb65cc2f7d25fc73cd84cf09c776198788a78beb764d5&X-Amz-SignedHeaders=host', { // The url which was generated
    //     headers: {
    //       'Content-Type': 'application/json'
    //   },
    // })
  }

  public async getPresignedURL(url: string) : Promise<any> {{
      // try {
        const res = await axios.get(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'text/plain'
          }
        })
        console.log('getPresignedUrl returns', res.data)
        return res.data.response
      // } catch(e) {
      //   console.warn('Error node.fetch:', e, 'from', url)
      // }
    }
  }
}
