import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-yesno-dialog',
  templateUrl: './yesno-dialog.component.html',
  styleUrls: ['./yesno-dialog.component.scss']
})
export class YesnoDialogComponent {
  @Input() public message: string = ''
  public language: string = this.translate.currentLang;
  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<YesnoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.translate.onLangChange.subscribe((event) => {
        this.data.language = event.lang;
      });
    }

  onNoClick(): void {
    this.dialogRef.close({delete:false});
  }

  onYesCLick(): void {
    this.dialogRef.close({delete:true});
  }

}
