import moment from "moment"
import { EIncidentType } from "src/app/concepts/incidents/incident-types"
import { ICacheOptions } from "src/app/providers/data-layers/fleet-data-layer"
import { getParams } from "../generic/utils"

export interface IReqFleetViolations {
  after: string
  before: string
  challengeRaisedBefore: string
  challengeRaisedAfter: string
  challengeResolvedBefore: string
  challengeResolvedAfter: string
  sort: 'asc' | 'desc'
  skip: number // use this for pagination
  limit: number
  division: string
  bookmarkedOnly: string
  tagSearch: string
  eventType: string
  sortBy: 'timestamp'
  challengeRaised: string
  challengeResolved: string
  driverId?: string
  assetId?: string
}

export const getParamsFleetViolations = (options: IReqFleetViolations) : string => {
  return getParams(options)
}


export const defaultViolationOpts: IReqFleetViolations = {
  after: moment().subtract(1, 'week').format('YYYY-MM-DD'),
  before: moment().format('YYYY-MM-DD'),
  challengeRaisedBefore: null,
  challengeRaisedAfter: null,
  challengeResolvedBefore: null,
  challengeResolvedAfter: null,
  sort: 'desc',
  skip: 0,
  limit: 4,
  division: null,
  bookmarkedOnly: null,
  tagSearch: null,
  eventType: null,
  sortBy: 'timestamp',
  challengeRaised: null,
  challengeResolved: null
}

export const generateViolationOptions = (options: any) => {
  return {...defaultViolationOpts, ...options}
}

// Generated by https://quicktype.io

export interface IResFleetViolationsCount {
  count: number;
}

export interface IResFleetViolations {
  limit: number;
  skip:  number;
  rows:  IResFleetViolationsRow[];
}

export enum VikingDrivingEventState {
  NeedsReview = "needs-review",
  Approved = "approved",
  Declined = "declined",
}

export interface IResFleetViolationsRow {
  _id: string
  cameraId: string
  location: any
  startWindowTimeMs: any
  videos: Videos;
  driverId:                       string;
  driverName?:                    string;
  tripId:                         string;
  eventType:                      EIncidentType;
  timestamp:                      string;
  timestampUTC:                   string;
  longitude:                      number;
  latitude:                       number;
  speed:                          number;
  accuracy:                       number;
  bearing:                        number;
  challengeRaised:                boolean;
  challengeResolved:              boolean;
  challengeAccepted:              boolean;
  eventIndex:                     number;
  timeZoneId:                     string;
  mediaFiles:                     MediaFile[];
  severity:                       number;
  device:                         Device;
  asset:                          Asset;
  reportBug?:                     boolean;
  harshAccelerationValue?:        number;
  eventVideoFile:                 string;
  bookmark:                       boolean;
  coachingCompleted:              boolean;
  tags:                           any[];
  videoDetails:                   VideoDetails;
  eventVideoFilenameUploadStatus: EventVideoFilenameUploadStatus;
  corneringAccelerationValue?:    number;
  harshBrakingAccelerationValue?: number;
  bookmarkedTimestamp?:           string;
  coachingInitiatedMetadata?:     CoachingInitiatedMetadata;
  state?:                         string;
  dataLabellingState?:            VikingDrivingEventState;
  eventTimeMs?:                   any
  requiredChunks?:                string;
  trimStartWindowTimeMs?:         any;
  trimEndWindowTimeMs?:           any;
}

export interface Videos {
  back: string;
  front: string;
}

export interface Asset {
  assetId: string;
}

export interface CoachingInitiatedMetadata {
  userType: string;
  email:    string;
  name:     string;
}

export interface Device {
  deviceId: string;
}

export interface EventVideoFilenameUploadStatus {
  status:           Status;
  uploadTimestamp?: string;
}

export enum Status {
  MarkedForUpload = "MARKED_FOR_UPLOAD",
  Uploaded = "UPLOADED",
}

export interface MediaFile {
  mediaFileIndex: number;
  mediaFile:      string;
  source:         Source;
  videoDetails:   VideoDetails;
  uploadStats:    EventVideoFilenameUploadStatus;
}

export enum Source {
  SideBySide = "SIDE_BY_SIDE",
}

export interface VideoDetails {
  videoWidth:     number;
  videoHeight:    number;
  videoBitrate:   number;
  includesPII:    boolean;
  videoQuality:   number;
  videoType:      VideoType;
  videoPiPType:   VideoPiPType;
  codec_type:     CodecType;
  videoFrameRate: number;
  piiSubframe:    PiiSubframe;
}

export enum CodecType {
  H264 = "h264",
}

export interface PiiSubframe {
  topLeftX: number;
  topLeftY: number;
  width:    number;
  height:   number;
}

export enum VideoPiPType {
  Road = "road",
}

export enum VideoType {
  SideBySide = "sideBySide",
}

