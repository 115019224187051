import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { UserTypeSelectorModule } from 'src/app/components/user-type-selector/user-type-selector.module';
import { VideoPlayerDialogComponent } from './video-player-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { OsmMapModule } from 'src/app/components/osm-map/osm-map.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VideoPlayerDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    UserTypeSelectorModule,
    OsmMapModule,
    TranslateModule
  ],
  exports:[TranslateModule],
})
export class VideoPlayerDialogModule {
}
