import { Injectable } from "@angular/core";
import { Observable, tap, map, BehaviorSubject, scan, Subscription, take, of } from "rxjs";
import { IResAggregateFleetTripsRow, generateAggregateTripsOptions, IReqAggregateFleetTrips, FleetTripsAggregateValue } from "src/app/concepts/api/lightmetrics/fleets/trips/get-aggregate-fleet-trips";
import { IPaginationConceptPage, IPaginationConfigure, IPaginationResult, limits } from "src/app/concepts/pagination/pagination";
import { FleetDataLayer, ICacheOptions } from "../data-layers/fleet-data-layer";
import * as _ from 'lodash';
import { IResFleetDriversRow, generateDriversOptions, IReqFleetDrivers } from "src/app/concepts/api/lightmetrics/fleets/drivers/get-fleet-drivers";
import { IReqFleetCoaching, IResFleetCoachingRow, generateFleetCoachingOptions } from "src/app/concepts/api/lightmetrics/fleets/coaching/get-fleet-coaching";

@Injectable({
  providedIn: 'root',
})
export class PaginationService {

  private _subs: Subscription[] = []
  private _increment: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public loaded = this._increment.pipe(scan((previous, increment) => previous + increment))
  private _numResourcesToLoad: number = 3
  public aggregateData: FleetTripsAggregateValue
  public driversData: IResFleetDriversRow[]
  public coachingData: IResFleetCoachingRow[]
  private _paginationReady: BehaviorSubject<IPaginationResult> = new BehaviorSubject<IPaginationResult>(null);
  private _loadedCounterSub: Subscription

  constructor(
    private fdl: FleetDataLayer
  ) {

  }

  public get paginationReady(): BehaviorSubject<IPaginationResult> {
    return this._paginationReady
  }

  private aggregateTripsDatasource = ((options: IReqAggregateFleetTrips): Observable<IResAggregateFleetTripsRow[]> => {
    return this.fdl.getAggregateTrips(generateAggregateTripsOptions(options))
  });

  private coachingDatasource = ((options: IReqFleetCoaching) : Observable<IResFleetCoachingRow[]> => {
    return this.fdl.getFleetCoaching(generateFleetCoachingOptions(options))
  });

  private _getTripsAggregate(options: ICacheOptions): Observable<IResAggregateFleetTripsRow[]> {
    const cacheName = `pagination-${new Date().getTime()}`
    this.fdl.addCache<IResAggregateFleetTripsRow>(cacheName, this.aggregateTripsDatasource, {
      ...options,
      limit: 1000000,
      skip: 0,
      groupBy: 'month',
    }, true)
    const cache = this.fdl.getCacheType<IResAggregateFleetTripsRow>(cacheName)
    return cache
      .getPage(0).pipe(tap(() => this.fdl.removeCache(cacheName)))
  }

  private _getDrivers() {
    this.fdl.addCache<IResFleetDriversRow>('drivers', this.driversDatasource, {
      after: null,
      before: null,
      limit: 400,
      skip: 0
    })

    this.fdl.getCacheType<IResFleetDriversRow>('drivers')
      .getPage(0)
      // .pipe(take(1))
      .subscribe(res => {
        this.driversData = res
        this._increment.next(1)
      })
  }

  private _getCoaching(options: ICacheOptions) {
    this.fdl.addCache<IResFleetCoachingRow>('coaching', this.coachingDatasource, {
      after: options.after,
      before: options.before,
      limit: 1000000,
      skip: 0
    }, true)

    this.fdl.getCacheType<IResFleetCoachingRow>('coaching')
      .getPage(0)
      // .pipe(take(1))
      .subscribe(res => {
        this.coachingData = res
        this._increment.next(1)
      })
  }

  private driversDatasource = ((options: IReqFleetDrivers): Observable<IResFleetDriversRow[]> => {
    return this.fdl.getDrivers(generateDriversOptions(options))
  });

  public getPagination(options: ICacheOptions) {
    this._loadedCounterSub?.unsubscribe()
    this._increment = new BehaviorSubject<number>(0);
    this.loaded = this._increment.pipe(scan((previous, increment) => previous + increment))

    this._loadedCounterSub = this.loaded.subscribe(loadedCounter => {
      if (loadedCounter === this._numResourcesToLoad) {
        this._extractAllData()
      }
    })
    this._getTripsAggregate(options)
      .pipe(map(res => {

        // first we want to total up all of the rows...

        this.aggregateData = res.reduce((acc: any, row) => {
          Object.keys(acc).length === 0
            ? acc = row.value
            : acc = {
              ...acc,
              tripDistance: acc.tripDistance + row.value.tripDistance,
              tripDuration: acc.tripDuration + row.value.tripDuration,
              tripCount: acc.tripCount + row.value.tripCount,
              eventCount: Object.keys(row.value.eventCount).reduce((eventObj, key) => {
                return { ...eventObj, [key]: acc.eventCount[key] + row.value.eventCount[key] }
              }, {})
            }
          return acc
        }, {})

      }))
      .subscribe(res => {
        this._increment.next(1)
      })

    this._getDrivers()
    this._getCoaching(options)
  }

  private _extractAllData(): void {
    const result = Object.keys(limits).reduce((pagination, page) => {
      const pageResult = Object.keys(limits[page]).reduce((accPage: IPaginationConceptPage, concept) => {
        if ((limits[page][concept] as IPaginationConfigure).method === 'violation') {
          accPage = {
            ...accPage, [concept]: {
              totalPages: Math.ceil(this.aggregateData.eventCount.total / (limits[page][concept] as IPaginationConfigure).limit),
              numberOfItemsPerPage: (limits[page][concept] as IPaginationConfigure).limit,
              totalItems: this.aggregateData.eventCount.total
            }
          }
        }
        if ((limits[page][concept] as IPaginationConfigure).method === 'driver') {
          accPage = {
            ...accPage, [concept]: {
              totalPages: Math.ceil(this.driversData.length / (limits[page][concept] as IPaginationConfigure).limit),
              numberOfItemsPerPage: (limits[page][concept] as IPaginationConfigure).limit,
              totalItems: this.driversData.length
            }
          }
        }
        if ((limits[page][concept] as IPaginationConfigure).method === 'coaching') {
          accPage = {
            ...accPage, [concept]: {
              totalPages: Math.ceil(this.coachingData.length / (limits[page][concept] as IPaginationConfigure).limit),
              numberOfItemsPerPage: (limits[page][concept] as IPaginationConfigure).limit,
              totalItems: this.coachingData.length
            }
          }
        }
        return accPage
      }, {} as IPaginationConceptPage)
      pagination[page] = pageResult
      return pagination
    }, {} as IPaginationResult)
    this._paginationReady.next(result)
  }
}
