import { getParams } from "../fleets/generic/utils";

export interface IReqRequestLiveStream {
    fleetId: string;
    tripId?: string | null;
    deviceId?: string | null;
    videoQuality?: number | null;
    videoResolution: string;
    unitSystem: string;
    videoType: string;
    videoTypeMainFrame: string;
}

export const defaultLiveStreamOpts: IReqRequestLiveStream = {
    fleetId: "JJSDF3762",
    tripId: null,
    deviceId: null,
    videoQuality: 10,
    videoResolution: "640x360",
    unitSystem: "imperial",
    videoType: "road",
    videoTypeMainFrame: "road"
}

export interface IResRequestLiveStream {
  streamRequestId: string;
  streamSessionURL: string;
}

export interface IReqStopLiveStream {
  fleetId: string;
  streamRequestId: string;
}

export const getParamsRequestLiveStream = (options: IReqRequestLiveStream) => {
  return getParams(options)
}

export const getParamsStopLiveStream = (options: IReqStopLiveStream) => {
  return getParams(options)
}
