import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { UserTypeSelectorModule } from 'src/app/components/user-type-selector/user-type-selector.module';
import { StreamingDialogComponent } from './streaming-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

const modules =  [
  CommonModule,
  DragDropModule,
  FormsModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatIconModule,
  MatInputModule,
  MatButtonModule,
  MatDialogModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  UserTypeSelectorModule,
  TranslateModule
]
@NgModule({
  declarations: [StreamingDialogComponent],
  imports:modules,
  exports:modules
})
export class StreamingDialogModule {
}
