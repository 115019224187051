<div fxFill fxLayout="column" fxLayoutAlign="space-between start" class="section-card">
  <!-- <div fxLayout="column" fxFlexF></div> -->
  <div [style.width]="'100%'" fxLayout="row" fxLayoutAlign="start">
    <!-- <div fxFlex="50" fxFlex.lt-md="100" class="section-header" fxLayout="row" fxLayoutAlign="space-between start" fxFullWidth> -->
      <h3 class="title card-title center">{{ 'incidentTrend.incidentTrend'  | translate}}</h3>
    <!-- </div> -->
  </div>
  <div [style.width]="'100%'" [style.height]="'100%'" fxLayout="row" fxLayoutAlign=" start">
    <mat-card fxFlex="100" fxFlex.lt-md="100">
      <div #card>
        <!-- <ng-container *ngIf="(loaded | async) === numResourcesToLoad+1; else loading"> -->
        <vex-widget-large-chart [options]="chartData$ | async"
            class="sm:col-span-2"></vex-widget-large-chart>
        <!-- </ng-container> -->
        <ng-template #loading>
          {{ 'incidentTrend.noData'  | translate}}
        </ng-template>
      </div>
    </mat-card>
  </div>
  <!-- <div [style.padding]="'20px'"> </div> -->
</div>
