import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { WidgetLargeChartModule } from 'src/@vex/components/widgets/widget-large-chart/widget-large-chart.module';
import { IncidentService } from 'src/app/providers/services/incident-service';
import { IncidentSelectorModule } from '../../incident-selector/incident-selector.module';
import { IncidentTrendComponent } from './incident-trend.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [IncidentTrendComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    IncidentSelectorModule,
    NgxTippyModule,
    WidgetLargeChartModule,
    TranslateModule,
  ],
  providers: [
    IncidentService,
    DatePipe
  ],
  exports: [
    IncidentTrendComponent,
    TranslateModule
  ]
})
export class IncidentTrendModule {
}
