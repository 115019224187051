<mat-tab-group [dir]="language === 'ar-AE' ? 'rtl' : 'ltr'" (selectedTabChange)="tabChange.emit($event.index)">
  <mat-tab label= "{{ 'liveViewAssetsTable.active' | translate }}">
    <div fxFlex fxLayout="column" fxLayoutAlign="stretch start" class="section-card">
      <div fxLayout="column" fxLayoutGap="20px" fxFlex [style.width]="'100%'">
        <vex-widget-table [loaded]="loaded$ | async" [columns]="liveStreamColumns"
          [paginationResponse]="paginationDigestActive.table_asset_details"
          (paginationRequest)="onPaginationRequest($event)"
          [data]="activeAssetsPageData"
          class="sm:col-span-2">
        </vex-widget-table>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'liveViewAssetsTable.inactive' | translate }}">
    <div fxFlex fxLayout="column" fxLayoutAlign="stretch start" class="section-card">
      <div fxLayout="column" fxLayoutGap="20px" fxFlex [style.width]="'100%'">
        <vex-widget-table [loaded]="loaded$ | async" [columns]="tableColumns"
          [paginationResponse]="paginationDigestNonActive.table_asset_details"
          (paginationRequest)="onPaginationRequest($event)"
          [data]="nonActiveAssetsPageData"
          class="sm:col-span-2">
        </vex-widget-table>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
