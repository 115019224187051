import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { IncidentSelectorModule } from '../../incident-selector/incident-selector.module';
import { TopDriversComponent } from './top-drivers.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TopDriversComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    IncidentSelectorModule,
    NgxTippyModule,
    WidgetTableModule,
    TranslateModule
  ],
  exports: [
    TopDriversComponent,
    TranslateModule]
})
export class TopDriversModule {
}
