import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { incidentData } from '../../mock-data/incident-data'
import { Observable, of } from 'rxjs';
import { Incident } from 'src/app/concepts/incidents/incidents';

@Injectable()
export class IncidentService {

  constructor(
    private http: HttpClient
  ) {
  }

  // TODO: Implement better trip getters as we start to use better data structures
  public getIncidents(ids: string[]): Observable<Incident[]> {
    const filtered = incidentData.filter(incident => ids.includes(incident.id))
    return of(filtered.map(incident => Object.assign(new Incident(), incident)))
  }
}
