import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { AlertDialogComponent } from './alert-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

const modules = [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    RouterModule,
    MatRippleModule
  ]

@NgModule({
  declarations: [AlertDialogComponent],
  imports: modules,
  exports: modules
})

export class AlertDialogModule {
}
