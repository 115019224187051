import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import { TripDetailsComponent } from '../trip-details/trip-details.component';
import { HomeComponent } from './home.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'trip-details/:driverId/:tripId',
    component: TripDetailsComponent,
    loadChildren: () => import('../../dashboards/trip-details/trip-details.module').then(m => m.TripDetailsModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule]
})
export class HomeRoutingModule {
}
