import { Injectable } from '@angular/core';
import { UserService } from './user-service';

export interface ITableStateCache {
	[key: string]: number
}

@Injectable()
export class TableStateService {

	private _cache: ITableStateCache

	constructor(
		private _userService: UserService
	) {
		this._loadCache()
	}

	public setPage(id: string, page: number): void {
		if (this._cache) {
			this._cache[id] = page
			this._saveCache()
		}
	}

	public getPage(id: string): number {
		return this._cache?.[id]
	}

	private _saveCache(): void {
		localStorage.setItem(`tablecache-${this._userService.getUser().email}`, JSON.stringify(this._cache))
	}

	private _loadCache(): void {
		this._cache = JSON.parse(localStorage.getItem(`tablecache-${this._userService.getUser().email}`)) || {}
	}
}
