import { getParams } from "../fleets/generic/utils";

export interface IReqMessageEventStream {
  maxDataKB: number
}

export const defaultMessageEventStreamOpts: IReqMessageEventStream = {
  maxDataKB: 512
}

// Generated by https://quicktype.io

export interface IResMessageEventStream {
  messages: Message[];
}

export interface Message {
  sdkSchema:          number;
  messageType:        MessageType;
  sequenceId:         string;
  tripId?:            string;
  fleetId?:           string;
  driverId?:          string;
  deviceId:           string;
  assetId?:           string;
  ignitionStatus:     boolean;
  gpsData:            GpsData;
  distance?:          number;
  timestampUTC:       string;
  ingestionTimestamp: string;
  parserSchema:       number;
  eventType?:         string;
}


export interface GpsData {
  latitude:  number;
  longitude: number;
  bearing:   number;
  accuracy:  number;
  speed:     number;
}

export enum MessageType {
  Event = "event",
  Gps = "gps",
}

export interface IReqResetEventStream {
  position: 'start' | 'end' | null
  timestamp: string
}

export const defaultResetEventStreamOpts: IReqResetEventStream = {
  position: null,
  timestamp: new Date(Date.now() - 1000 * 60 * 60).toISOString()
}

export const getParamsMessageEventStream = (options: IReqMessageEventStream) => {
  return getParams(options)
}

export const getParamsResetEventStream = (options: IReqResetEventStream) => {
  return getParams(options)
}
