import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';

import { environment } from '../../../environments/environment';

export interface ICognitoUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable()
export class CognitoOldService {

  // public authenticationSubject: BehaviorSubject<any>;

  constructor() {

    // this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public async signIn(user: ICognitoUser): Promise<any> {
    console.log('legacy auth system sign in')
    console.log('user', user)
    Amplify.configure({
      Auth: environment.cognito.migration,
    });
    await Auth.signIn(user.email, user.password)
    console.log('all good from migration login')
  }

  // public async signOut(): Promise<any> {
  //   return Auth.signOut()
  //   .then(() => {
  //     // this.authenticationSubject.next(false);
  //   });
  // }

  // public async isAuthenticated(): Promise<boolean> {
  //   if (this.authenticationSubject.value) {
  //     return Promise.resolve(true);
  //   } else {
  //     return this.getUser()
  //     .then((user: any) => {
  //       if (user) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }).catch(() => {
  //       return false;
  //     });
  //   }
  // }

  // public async getUser(): Promise<any> {
  //   return Auth.currentUserInfo();
  // }

  // public async updateUser(user: ICognitoUser): Promise<any> {
  //   return Auth.currentUserPoolUser()
  //   .then((cognitoUser: any) => {
  //     return Auth.updateUserAttributes(cognitoUser, user);
  //   });
  // }
}
