<div id="stream-dialog" class="dialog" cdkDragBoundary=".cdk-overlay-container" cdkDrag
  cdkDragRootElement=".cdk-overlay-pane">
  <div class="second-button" cdkDragHandle>
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div id="close-button" class="first-button" hidden>
    <button (click)="dialogRef.close(saved)" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title>{{ 'streaming.liveStreaming' | translate}}<span class="info-window-key-nontable"> |
      {{data.data.assetId}}</span></h1>
  <div mat-dialog-content style="margin-top:-15px">
    <div fxFlex="1 1 100%">
      <div *ngIf="streamDataLoading$ | async" class="spinner-overlay" fxLayout="row" fxLayoutAlign="center center">
        <div class="spinner">
          <mat-progress-spinner diameter="200" role="progressbar" tabindex="-1"
            class="spinner mat-progress-spinner mat-progress-spinner-indeterminate-animation" mode="indeterminate">
            <svg preserveAspectRatio="xMidYMid meet" focusable="false" viewBox="0 0 12 12">
              <circle cx="50%" cy="50%" r="5"
                style="animation-name: mat-progress-spinner-stroke-rotate-20; stroke-dasharray: 31.4159px; stroke-width: 10%;">
              </circle>
            </svg>
          </mat-progress-spinner>
        </div>
      </div>

      <div id="viewer" class="d-none" hidden>
        <!-- <h5>From device</h5> -->
        <div class="w-full">
          <video id="video-live-stream" class="w-full" autoplay playsinline controls></video>
        </div>
      </div>
      
      <div id="camera-facing-form" hidden>
        <div fxLayout="column" class="form-radio-button-group">
          <label>{{ 'streaming.videoFormat' | translate }}</label>
          <mat-radio-group aria-labelledby="camera-facing-radio-button-group" fxLayout="column"
            [value]="cameraFacing"  (change)="streamCameraFacing($event)">
            <mat-radio-button class="form-radio-button"
              [checked]="cameraFacing===cameraFacings[0]"
              [value]="cameraFacings[0]">{{ 'streaming.road' | translate }}</mat-radio-button>
            <mat-radio-button class="form-radio-button"
              [checked]="cameraFacing===cameraFacings[1]"
              [value]="cameraFacings[1]">{{ 'streaming.driver' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div id="stream-timer" hidden>
        {{ 'streaming.streamTime' | translate }}: {{formatTime(streamTime)}}
      </div>
    </div>
  </div>
</div>