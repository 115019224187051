import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { eDurationToString, getDurationDefault, getDurationPickerData, getLabelData, IDateRangeData } from 'src/app/concepts/utils/chrono-utils';

@Component({
  selector: 'vex-duration-selector',
  templateUrl: './duration-selector.component.html',
  styleUrls: ['./duration-selector.component.scss']
})
export class DurationSelectorComponent implements OnInit {

  @Input() pageContext: string
  @Output() change: EventEmitter<string> = new EventEmitter();

  public labelData: IDateRangeData
  public selected: string
  public language: string = this.translate.currentLang

  constructor(private translate: TranslateService) { 
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  ngOnInit(): void {
    this.labelData = getDurationPickerData('home');
    this.selected = 'chornoUtils.month'
    this.onChange('chornoUtils.month')
  }

  public labels = () : string[] => getLabelData(this.labelData);

  onChange(event: any) : void {
    this.change.emit(event.split('.')[1])
  }

}
