import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { from, Observable, of, Subscription } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { Md5 } from 'ts-md5/dist/md5';
import { CognitoService, ICognitoUser } from 'src/app/providers/services/cognito-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));
  avatarUrl: string

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;

  user: any

  public language: string = this.translate.currentLang;

  private _subAuth: Subscription
  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private readonly popoverService: PopoverService,
              private readonly dialog: MatDialog,
              private authService: CognitoService,
              private translate: TranslateService) {  }

  ngOnInit() {
    this.authService.getUser()
    .then(user => {
      if(user) {
        this._setupUser(user)
      } else {
        this.avatarUrl = null
        this.user = null
      }
    })
    this._subAuth = this.authService.authenticationSubject.subscribe(response => {
      console.log('***SUBAUTH RECV BEHAVIOUR CHANGE!***')
      if(response === true) {
        this.authService.getUser()
        .then(user => {
          if(user) {
            this._setupUser(user)
          } else {
            this.avatarUrl = null
            this.user = null 
          }
        })
      } else {
        this.avatarUrl = null
        this.user = null
      }
    })

  }

  ngOnDestroy(): void {
    this._subAuth.unsubscribe()
  }

  public email(): string {
    return this.user?.attributes?.email
  }

  public name(): string {
    return this.user?.attributes?.name
  }

  private _setupUser(user: any) {
    const emailHash = Md5.hashStr(user.attributes.email.trim().toLowerCase());
    this.avatarUrl = `https://www.gravatar.com/avatar/${emailHash}`
    this.user = user
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }
}
