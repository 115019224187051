import { getParams } from "../generic/utils"

export interface IReqOngoingTrips {
  lastUpdatedWithin: string
  sort: 'asc' | 'desc'
  limit: number
  skip: number // use this for pagination
}

export const defaultOngoingTripsOpts: IReqOngoingTrips = {
  lastUpdatedWithin: (1000 * 60).toString(),
  limit: 500,
  skip: 0,
  sort: 'asc',
}

export const getParamsOngoingTrips = (options: IReqOngoingTrips) : string => {
  return getParams(options)
}

export const generateOngoingTripsOptions = (options: IReqOngoingTrips) => {
  return {...defaultOngoingTripsOpts, ...options}
}

// Generated by https://quicktype.io

export interface IResOngoingTrips {
  limit: number;
  skip:  number;
  rows:  IResOngoingTripsRow[];
}

export interface IResOngoingTripsRow {
  fleetId:                  string;
  tripId:                   string;
  tripDistance:             number;
  startTime:                string;
  startTimeUTC:             string;
  driverId:                 string;
  driverName?:              string;
  eventCount:               { [key: string]: number };
  customEventCount:         CustomEventCount;
  asset:                    Asset;
  dvrEnabled:               boolean;
  cameraSerialId:           string;
  timezoneOffset:           number;
  ongoing:                  boolean;
  timeZoneId:               string;
  device:                   Device;
  ignitionId:               string;
  statusUpdateTimestamp:    string;
  firstLocation:            Location;
  lastKnownLocation:        Location;
  videoCaptureStartTimeUTC: string;
  videoCaptureStartTime:    string;
  cameraConnected:          boolean;
  duration:                 number;
  edvrEnabled:              boolean;
  tripDataUploaded:         boolean;
}

export interface Asset {
  assetId:         string;
  dutyType:        string;
  cameraType:      string;
  ridecamPlusPlan: string;
  plusPackages:    string[];
  packages:        string[];
  metadata:        Metadata;
}

export interface Metadata {
}

export interface CustomEventCount {
  total: number;
}

export interface Device {
  model:    string;
  deviceId: string;
}

export interface Location {
  latitude:  number;
  longitude: number;
  bearing:   number;
  speed:     number;
  accuracy:  number;
}
