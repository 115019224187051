import { getParams } from "../lightmetrics/fleets/generic/utils"

export interface IReqVikingFleetDriver {
	driverId: string
}

export interface IResVikingFleetDriver {
	driverId: string
	driverName: string
	fleetId: string
	email?: string
	telephone?: string
	assetId?: string
	cameraInstalled?: boolean
}

export const getParamsVikingFleetDrivers = (options: IReqVikingFleetDriver) : string => {
  return getParams(options)
}

export interface IReqChangeDriverIdOptions {
	newDriverId: string
}

export const getParamsChangeDriverId = (options: IReqChangeDriverIdOptions) : string => {
	return getParams(options)
}