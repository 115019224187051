import moment from "moment";
import { ICacheOptions } from "src/app/providers/data-layers/fleet-data-layer";
import { defaultDriversOpts } from "../drivers/get-fleet-drivers";
import { getParams } from "../generic/utils";

export interface IResFleetAssetsRow {
    assetId:      string;
    assetName:    string;
    tripDistance: number;
    eventCount:   { [key: string]: number };
    tripCount:    number;
    tripDuration: number;
    score:        number;
    driverName?:  string;
  }
  
  export interface IResFleetAssets {
    rows: IResFleetAssetsRow[];
  }
  
  export interface IReqFleetAssets {
    after: string
    before: string
    assetId?: string
  }

  export interface IResAdminAssets {
    rows: IResAdminAssetsRow[]
  }

  export interface IResAdminAssetsRow {
    assetId: string
    assetName?: string
    fleetId: string
  }

  export const getParamsFleetAssets = (options: IReqFleetAssets) : string => {
    return getParams(options)
  }
  
  export const defaultAssetsOpts: IReqFleetAssets = {
    after: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    before: moment().format('YYYY-MM-DD'),
    assetId: null
  }
  
  export const generateAssetsOptions = (options?: IReqFleetAssets) => {
    return {...defaultAssetsOpts, ...options}
  }