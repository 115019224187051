import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing.module';
import { DurationSelectorModule } from 'src/app/components/duration-selector/duration-selector.module';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QuadStatsModule } from 'src/app/components/trips/quad-stats/quad-stats.module';
import { HighlightsModule } from 'src/app/components/trips/highlights/highlights.module';
import { IncidentService } from 'src/app/providers/services/incident-service';
import { TopDriversModule } from 'src/app/components/trips/top-drivers/top-drivers.module';
import { RequireCoachingModule } from 'src/app/components/trips/require-coaching/require-coaching.module';
import { IncidentSummaryModule } from 'src/app/components/incidents/incident-summary/incident-summary.module';
import { IncidentTrendModule } from 'src/app/components/incidents/incident-trend/incident-trend.module';
import { PaginationService } from 'src/app/providers/services/pagination-service';
import { LiveViewModule } from '../live-view/live-view.module';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    DurationSelectorModule,
    QuadStatsModule,
    HighlightsModule,
    TopDriversModule,
    RequireCoachingModule,
    IncidentSummaryModule,
    IncidentTrendModule,
    MatCardModule,
    FlexLayoutModule,
    LiveViewModule,
    MatOptionModule,
    MatSelectModule,
    TranslateModule
  ],
  exports:[TranslateModule],
  providers: [
    IncidentService,
    PaginationService
  ]
})
export class HomeModule {
}
