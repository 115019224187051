import { Injectable } from '@angular/core';
import { getAdminUserPortal} from './viking-api-service';

export interface IPortalUser {
	name: string
	fleetId: string
	email: string
	password?: string
}

@Injectable()
export class UserService {

  private _currentUser: IPortalUser
  private id: number
  constructor(
  ) {
    this.id = Math.random() * 1000000
  }

  public async setUser(user: any) {
    console.log('USER: setUser', user || 'NULL')
    const portalUser = user ? await this.getPortalUser(user) : null
    console.log('USER: Got Portal User', portalUser, 'from cognito user', user)
    this._currentUser = portalUser
    console.log('USER - current user', this._currentUser)
  }

  public getUser(): IPortalUser {
    console.log(`USER: getUser(${this.id})`, this._currentUser)
    return this._currentUser
  }

  public async getPortalUser(email: string) : Promise<any> {
    return await getAdminUserPortal(`user/${email.toLocaleLowerCase()}/`)
  }

}
