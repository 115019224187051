import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user-service';
import axios from 'axios';

export interface ICognitoUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {

  public authenticationSubject: BehaviorSubject<any>;

  constructor(
    private _http: HttpClient,
    private _userService: UserService
  ) {
    Amplify.configure({
      Auth: environment.cognito.auth,
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public signUp(user: ICognitoUser): Promise<any> {
    return Auth.signUp({
      username: user.email,
      password: user.password,
    });
  }

  public confirmSignUp(user: ICognitoUser): Promise<any> {
    return Auth.confirmSignUp(user.email, user.code);
  }

  public async signIn(user: ICognitoUser): Promise<any> {
    Amplify.configure({
      Auth: environment.cognito.auth,
    });
    console.log('sign in', user)
    const res = await Auth.signIn(user.email, user.password)
    console.log('signed in', res)
    await this._userService.setUser(user.email)
    this.authenticationSubject.next(true);
  }

  public async signOut(): Promise<any> {
    await Auth.signOut()
    this.authenticationSubject.next(false);
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      console.log('isAuthenticated subject has value')
      return Promise.resolve(true);
    } else {
      try {
        console.log('isAuthenticated subject has no value')
        const user = await this.getUser()
        if (user) {
          console.log('calling setUser with', user)
          await this._userService.setUser(user.username)
          return true;
        } else {
          console.log('null user from auth, setting null')
          await this._userService.setUser(null)
          return false;
        }
      } catch(err) {
        console.log('isAuthenticated Error (setting user to null)', err)
        await this._userService.setUser(null)
        return false;
      }
    }
  }

  public async getUser(): Promise<any> {
    return await Auth.currentUserInfo();
  }

  public async updateUser(user: ICognitoUser): Promise<any> {
    const cognitoUser = await Auth.currentUserPoolUser()
    return Auth.updateUserAttributes(cognitoUser, user);
  }

  public async resendCode(email: string): Promise<any> {
    const res = await Auth.resendSignUp(email)
    console.log('res', res)
  }

  public async sendForgotCode(email: string): Promise<any> {
    return await Auth.forgotPassword(email)
  }

  public async forgotPasswordSubmit(email: string, code: string, newPassword: string): Promise<any> {
    return await Auth.forgotPasswordSubmit(email, code, newPassword)
  }

}
