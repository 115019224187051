export class GeocoderResponse {
  status: string;
  error_message: string;
  results: google.maps.GeocoderResult[];

  constructor(status: string, results: google.maps.GeocoderResult[]) {
    this.status = status;
    this.results = results;
  }
}

export class NominatimSearchResponse {
  constructor (
    public latitude: number,
    public longitude: number,
    public displayName: string
  ) {
  }
}

// Generated by https://quicktype.io

export interface NominatimReverseResponse {
  place_id:     string;
  licence:      string;
  osm_type:     string;
  osm_id:       string;
  boundingbox:  string[];
  lat:          string;
  lon:          string;
  display_name: string;
  class:        string;
  type:         string;
  importance:   number;
  icon:         string;
  address:      Address;
  extratags:    Extratags;
}

export interface Address {
  city:             string;
  state_district:   string;
  state:            string;
  "ISO3166-2-lvl4": string;
  postcode:         string;
  country:          string;
  country_code:     string;
}

export interface Extratags {
  capital:    string;
  website:    string;
  wikidata:   string;
  wikipedia:  string;
  population: string;
}
