import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ApiChooserService {

	private _vikingReady : string[] = [
		'aggregate',
		'drivers',
    	'trips',
    	'drivers/:driverId/trips/:tripId',
		'violations',
		'drivers/:driverId/trips',
		'drivers/:driverId/violations',
		'drivers/:driverId/aggregate',
		'assets',
		'assets/:assetId/trips',
		'assets/:assetId/violations', 
		'assets/:assetId/aggregate',
		'assets/:assetId/trips/:tripId'
	]

  constructor(
  ) {

  }

	canViking(path: string) : boolean {
		console.log('path',path, this._vikingReady.includes(path))
		return this._vikingReady.includes(path)
	}

}

