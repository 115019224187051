import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FleetDataLayer } from 'src/app/providers/data-layers/fleet-data-layer';
import { IReqRequestLiveStream } from 'src/app/concepts/api/lightmetrics/live-streaming/live-stream-requests';
import Hls from 'hls.js';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'streaming-dialog',
  templateUrl: './streaming-dialog.component.html',
  styleUrls: ['./streaming-dialog.component.scss']
})

export class StreamingDialogComponent implements OnInit, OnDestroy {
  public saved: any
  public language: string = this.translate.currentLang

  public streamDataLoading$: Observable<boolean> = of(true);
  isStreamDataLoading: boolean = true;
  cameraFacing: string = 'road'
  cameraFacings = ["road", "driver"]
  streamTime: number = 0;
  streamTimeInterval;

  constructor(
    public dialogRef: MatDialogRef<StreamingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public elementRef: ElementRef,
    public fdl: FleetDataLayer,
    private translate: TranslateService
  ) {
    this.saved = data
    this.translate.onLangChange.subscribe((event) => {
      this.language = event.lang;
    });
  }

  private onBeforeUnload = async () => {
    if (!this.isStreamDataLoading) {
      this.sleep(1000)
      console.log("sending actual req to stop");
      this.fdl.stopLiveStream({fleetId: this.data.data.fleetId, streamRequestId: this.saved.data.streamData.streamRequestId})
      this.sleep(1000)
    }
  }

  ngOnInit(): void {
    window.addEventListener('beforeunload', this.onBeforeUnload);

    var opts: IReqRequestLiveStream = {
      fleetId: this.data.data.fleetId,
      tripId: null,
      deviceId: this.data.data.deviceId,
      videoQuality: null,
      videoResolution: "640x360",
      unitSystem: "imperial",
      videoType: "road",
      videoTypeMainFrame: "road"
    }

    this.startStream(opts)
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  public async streamCameraFacing(e) {
    this.cameraFacing = e.value

    this.elementRef.nativeElement.querySelector('#viewer').setAttribute("hidden", true);
    this.elementRef.nativeElement.querySelector('#close-button').setAttribute("hidden", true);
    this.elementRef.nativeElement.querySelector('#camera-facing-form').setAttribute("hidden", true);
    this.elementRef.nativeElement.querySelector('#stream-timer').setAttribute("hidden", true);

    this.streamDataLoading$ = of(true)
    this.isStreamDataLoading = true

    var opts: IReqRequestLiveStream = {
      fleetId: this.data.data.fleetId,
      tripId: null,
      deviceId: this.data.data.deviceId,
      videoQuality: null,
      videoResolution: "640x360",
      unitSystem: "imperial",
      videoType: e.value,
      videoTypeMainFrame: e.value
    }

    this.fdl.stopLiveStream({fleetId: this.data.data.fleetId, streamRequestId: this.saved.data.streamData.streamRequestId})
    .then((response) => {
      this.pauseTimer()
      this.startStream(opts)
    })
  }

  private async startStream(opts) {
    this.fdl.requestLiveStream(opts).then((response) => {
      this.startTimer();
      this.elementRef.nativeElement.querySelector('#viewer').removeAttribute("hidden")
      this.elementRef.nativeElement.querySelector('#close-button').removeAttribute("hidden")
      this.elementRef.nativeElement.querySelector('#camera-facing-form').removeAttribute("hidden")
      this.elementRef.nativeElement.querySelector('#stream-timer').removeAttribute("hidden")

      this.streamDataLoading$ = of(false)
      this.isStreamDataLoading = false

      this.saved.data.streamData = response
      var video: HTMLMediaElement = this.elementRef.nativeElement.querySelector('#video-live-stream');
      var videoSrc = response.streamSessionURL;
      if (Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
        video.play();
      }
      // HLS.js is not supported on platforms that do not have Media Source
      // Extensions (MSE) enabled.
      //
      // When the browser has built-in HLS support (check using `canPlayType`),
      // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
      // element through the `src` property. This is using the built-in support
      // of the plain video element, without using HLS.js.
      //
      // Note: it would be more normal to wait on the 'canplay' event below however
      // on Safari (where you are most likely to find built-in HLS support) the
      // video.src URL must be on the user-driven white-list before a 'canplay'
      // event will be emitted; the last video event that can be reliably
      // listened-for when the URL is not on the white-list is 'loadedmetadata'.
    //   else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    //     video.src = videoSrc;
    //   }
      console.log("============")
      console.log(response)
    })
  }

  startTimer() {
    this.streamTimeInterval = setInterval(() => {
      this.streamTime++;
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.streamTimeInterval);
  }

  stopTimer() {
    clearInterval(this.streamTimeInterval);
    this.streamTime = 0;
  }
  
  formatTime(value: number): string {
    var sec_num = value; 
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    var clock = ""

    if (hours   < 10) {clock   += `0${hours}:`;} else {clock   += `${hours}:`}
    if (minutes < 10) {clock   += `0${minutes}:`} else {clock   += `${minutes}:`}
    if (seconds < 10) {clock   += `0${seconds}`} else {clock   += `${seconds}`}

    return clock;
  }

  private sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
  }
}
